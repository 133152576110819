/**
 * @module moduleBonusLocations
 */

import { db } from '@/firebaseConfig.js'
import { BonusLocationsPrototype } from '@/components/prototypes/bonuslocations.js'
/**
 * @function assignPrototype
 * @description This function takes an array of routestop objects and ensures the array is returned with all objects correctly set to the RouteStopsPrototype prototype. If the correct prototype is already set on the objects in the array, the original array will be returned.
 * @author Patrick Nijsters
 * @memberof module:moduleBonusLocations
 * @param {Array.<Object>} _array An array of routestop object that might or might not have the correct prototype set.
 * @returns {Array.<RouteStopsPrototype>}
 */
function assignPrototype(_array) {
  _array.forEach(
    (element) => (element.__proto__ = BonusLocationsPrototype.prototype)
  )
  return _array
}

const moduleBonusLocations = {
  namespaced: true,
  state: {
    BonusLocations: []
  },
  mutations: {
    /**
     * @function BonusLocationsCreateUpdateMutation
     * @author Patrick Nijsters
     * @description Vuex **mutation** that either updates a single Bonus Location object if it already exists or creates a new entry in the Vuex state store based on the BonusLocation object that is pased to it (the bonus name is used as search key)
     * @memberof module:moduleBonusLocations
     * @param {Object} _state Vuex state object
     * @param {BonusLocationsPrototype} _bonus
     * @returns {Void}
     */
    BonusLocationsCreateUpdateMutation: (_state, _bonus) => {
      // we do not want any undefined key/value pairs in objects so make sure we
      // check and adjust to null if an undefined key/value pair is found
      Object.keys(_bonus).forEach((key) => {
        if (_bonus[key] === undefined) {
          _bonus[key] = null
        }
      })
      const item = _state.BonusLocations.find(
        (bonuslocation) => bonuslocation.bonusid === _bonus.bonusid
      )
      if (item) {
        // existing bonus with this name, replace it
        _state.BonusLocations.splice(
          _state.BonusLocations.indexOf(item),
          1,
          _bonus
        )
      } else {
        // bonus does not exist yet, add it to the end of the array
        _state.BonusLocations.push(_bonus)
      }
    },

    /**
     * @function BonusLocationsClearMutation
     * @author Patrick Nijsters
     * @description Vuex **mutation** that deletes all BonusLocation objects from the Vuex state store
     * @memberof module:moduleBonusLocations
     * @param {Object} _state Vuex state object
     * @returns {Void}
     */
    BonusLocationsClearMutation: (_state) => {
      _state.BonusLocations = []
    },

    /**
     * @function BonusLocationsDeleteMutation
     * @author Patrick Nijsters
     * @description Vuex **mutation** that deletes a single BonusLocation object from the state store based on the name of a bonus location that is passed to this function through the BonusLocation object passed to it
     * @memberof module:moduleBonusLocations
     * @param {Object} _state Vuex state object
     * @param {BonusLocationsPrototype} _bonus
     * @returns {Void}
     */
    BonusLocationsDeleteMutation: (_state, _bonus) => {
      const item = _state.BonusLocations.find(
        (bonuslocation) => bonuslocation.name === _bonus.name
      )
      if (item) {
        _state.BonusLocations.splice(_state.BonusLocations.indexOf(item), 1)
      }
    },

    /**
     * @function BonusLocationsReadAllMutation
     * @author Patrick Nijsters
     * @description Vuex **mutation** that stores a BonusLocation object passed to it in the Vuex state store
     * @memberof module:moduleBonusLocations
     * @param {Object} _state Vuex state object
     * @param {BonusLocationsPrototype} _bonus
     * @returns {Void}
     */
    BonusLocationsReadAllMutation: (_state, _bonus) => {
      _state.BonusLocations.push(_bonus)
    },

    /**
     * @function BonusLocationsDeleteByCategoryMutation
     * @author Patrick Nijsters
     * @description Vuex **mutation** that deletes a group of BonusLocation objects in the Vuex state store based on the category these bonuses belong to
     * @memberof module:moduleBonusLocations
     * @param {Object} _state Vuex state object
     * @param {D|A|T|S|N|DC|AC|TC} _category
     * @returns {Void}
     */
    BonusLocationsDeleteByCategoryMutation: (_state, _category) => {
      let result = _state.BonusLocations.filter(
        (bonusLocation) => bonusLocation.category === _category
      )
      for (let item in result) {
        let index = _state.BonusLocations.indexOf(item)
        _state.BonusLocations.splice(index, 1)
      }
    }
  },
  actions: {
    /**
     * @function BonusLocationsReadAllAction
     * @author Patrick Nijsters
     * @description Vuex **action** which uses the Firestore API to read all bonus locations from the specified collection, by globally unique rallyid, into the Vuex state by using a Vuex mutation
     * @memberof module:moduleBonusLocations
     * @async
     * @param {VuexContext} _context Vuex context object
     * @param {UUIDv4} _rallyid The UUIDv4 globally unique identifier of the rally object whose bonus locations need to be read into the Vuex state store
     * @returns {Void}
     * @see module:moduleBonusLocations.BonusLocationsReadAllMutation
     * @requires firebaseConfig.js
     * @example BonusLocationsReadAllAction({VuexContext object}, '0d145317-5478-4627-bf2d-3ece37cf45b3')
     */
    async BonusLocationsReadAllAction(_context, _rallyid) {
      _context.commit('BonusLocationsClearMutation')
      const allbonuslocations = await db
        .collection(
          `users/${_context.rootState.moduleUser.CurrentUser.uid}/rallies/bonuslocations/${_rallyid}`
        )
        .get()

      allbonuslocations.docs.forEach((bonuslocation) => {
        let savedocument = { ...bonuslocation.data() }
        savedocument.__proto__ = BonusLocationsPrototype.prototype
        _context.commit('BonusLocationsReadAllMutation', savedocument)
      })
    },

    /**
     * @function BonusLocationsCreateUpdateAction
     * @author Patrick Nijsters
     * @description Vuex **action** which
     * 1. calls the firestore API to update an existing bonus location in the specified Firestore collection (if found), or create new bonus location in the specified Firestore collection (if not found)
     * 2. calls a Vuex mutation that updates an existing bonus location (if found), or creates a new bonus location (if not found).
     * @memberof module:moduleBonusLocations
     * @async
     * @param {BonusLocationsPrototype} _bonus Bonus Locations object specifying the bonus location and the Firestore collection to be used
     * @param {VuexContext} _context Vuex context {state, rootState, commit, dispatch, getters, rootGetters}
     * @returns {Void}
     * @see module:moduleBonusLocations.BonusLocationsCreateUpdateMutation
     * @requires firebaseConfig.js
     * @example BonusLocationsCreateUpdateAction(
     *    {VuexContext object},
     *    {Bonus Locations object}
     *  )
     */
    async BonusLocationsCreateUpdateAction(_context, _bonus) {
      db.collection(
        `users/${_context.rootState.moduleUser.CurrentUser.uid}/rallies/bonuslocations/${_context.rootState.moduleUser.UserProfile.activerallyid}`
      )
        .doc(_bonus.bonusid)
        .set(JSON.parse(JSON.stringify(_bonus)))
      _context.commit('BonusLocationsCreateUpdateMutation', _bonus)
    },

    /**
     * @function BonusLocationsDeleteByCategoryAction
     * @author Patrick Nijsters
     * @description Vuex **action** which
     * 1. calls the firestore API to delete BonusLocation objects in the specified Firestore collection based on the category of the bonus location
     * 2. calls a Vuex mutation that deletes an existing BonusLocation object based on the category of the bonus location using a Vuex mutation
     * @memberof module:moduleBonusLocations
     * @async
     * @param {VuexContext} _context Vuex context object
     * @param {Object} _inputparameters
     * @param {UUIDv4} _inputparameters.rallyid The UUIDv4 globally unique identifier of the rally object whose bonus locations need to be deleted the Vuex state store and removed from the Firestore
     * @param {Array<D|A|T|S|N|DC|AC|TC>} _inputparameters.categories
     * @returns {Void}
     * @see module:moduleBonusLocations.BonusLocationsDeleteByCategoryMutation
     * @requires firebaseConfig.js
     * @example BonusLocationsDeleteByCategoryAction({VuexContext object}, {rallyid: '0d145317-5478-4627-bf2d-3ece37cf45b3', categories: ['A', 'D', 'T'])
     */
    async BonusLocationsDeleteByCategoryAction(_context, _inputparameters) {
      const deletebonuslocations = _context.state.BonusLocations.filter(
        (bonuslocation) => {
          return _inputparameters.categories.some(
            (category) => category === bonuslocation.category
          )
        }
      )
      if (!deletebonuslocations) return
      await deletebonuslocations.forEach(async (bonuslocation) => {
        await db
          .collection(
            `users/${_context.rootState.moduleUser.CurrentUser.uid}/rallies/bonuslocations/${_inputparameters.rallyid}`
          )
          .doc(bonuslocation.bonusid)
          .delete()
      })

      _inputparameters.categories.forEach((category) => {
        _context.commit('BonusLocationsDeleteByCategoryMutation', category)
      })
    },

    /**
     * @function BonusLocationsDeleteAction
     * @author Patrick Nijsters
     * @description Vuex **action** which
     * 1. calls the Firestore API to delete all BonusLocation object for a specified rally identified by its globally unique rallyid
     * 2. uses Vuex mutation to delete all BonusLocation objects from the Vuex state store
     * @memberof module:moduleBonusLocations
     * @async
     * @param {VuexContext} _context Vuex context object
     * @param {Object} _inputparameters
     * @param {UUIDv4} _inputparameters.rallyid The UUIDv4 globally unique identifier of the rally object whose bonus locations need to be deleted the Vuex state store and removed from the Firestore
     * @param {BonusLocationsPrototype} _inputparameters.bonus
     * @see module:moduleBonusLocations.BonusLocationsDeleteMutation
     * @requires firebaseConfig.js
     * @returns {Void}
     * @todo Is there a more efficient way (better API call) to delete every bonus location in the Firestore rather than iterating over them?
     */
    async BonusLocationsDeleteAction(_context, _inputparameters) {
      await db
        .collection(
          `users/${_context.rootState.moduleUser.CurrentUser.uid}/rallies/bonuslocations/${_inputparameters.rallyid}`
        )
        .doc(_inputparameters.bonus.bonusid)
        .delete()
      _context.commit('BonusLocationsDeleteMutation', _inputparameters.bonus)
    },

    /**
     * @function BonusLocationsClearAction
     * @author Patrick Nijsters
     * @description Vuex **action** which calls a Vuex mutation that clears ('deletes') all bonus locations from the Vuex state store only (the function does not empty the Firestore corresponding collection)
     * @memberof module:moduleBonusLocations
     * @param {VuexContext} _context Vuex context {state, rootState, commit, dispatch, getters, rootGetters}
     * @see module:moduleBonusLocations~BonusLocationsClearMutation
     * @returns {Void}
     */
    BonusLocationsClearAction: (_context) => {
      _context.commit('BonusLocationsClearMutation')
    }
  },
  getters: {
    /**
     * @function BonusLocationsByIdGetter
     * @description Vuex **getter** thatr returns a single BonusLocations object tht corresponds to the globally unique UUIDv4 that was provided as inpyt
     * @author Patrick Nijsters
     * @memberof module:moduleBonusLocations
     * @param {Object} _state Vuex state object
     * @param {UUIDv4} _bonusid
     * @returns {BonusLocationsPrototype}
     */
    BonusLocationsByIdGetter: (_state) => (_bonusid) => {
      const bonuslocation = _state.BonusLocations.find(
        (bonuslocation) => bonuslocation.bonusid === _bonusid
      )
      if (!bonuslocation) return new BonusLocationsPrototype()
      bonuslocation.__proto__ = BonusLocationsPrototype.prototype
      return bonuslocation
    },
    /**
     * @function BonusLocationsAttainedCombosGetter
     * @author Patrick Nijsters
     * @description Vuex **getter* that returns an array of BonusLocation objects. The returned array containes one BonusLocation object for each attained combo in the planned route. A combo is considered attained when every single combo constituent of this bonus combo is included in the planned route. For example, if a the combo with the name 'COMBO' is made up of 3 bonus stops 'STOP1', 'STOP2', 'STOP3'. The combo bonus 'COMBO' is considered attained when the planned route includes all three stops 'STOP1', 'STOP2' and 'STOP3'. The output of this function only contains BonusLocations object for the combos, not the individual combo constituents.
     * @memberof module:moduleBonusLocations
     * @param {Object} _state Vuex state object
     * @param {Object} _getters Vuex getters object
     * @param {Object} _rootState Vuex rootState object
     * @param {Object} _rootGetters Vuex rootGetters object
     * @returns {Array.<BonusLocationsPrototype>} Returns an array of combo BonusLocations objects attained in this route
     * @todo NEEDS CLEANUP FOR EFFICIENCY
     * @example This function returns an array that looks like:
     * [
     *  {BonusLocation for attained combo #1},
     *  {BonusLocation for attained combo #2}
     * ]
     */
    BonusLocationsAttainedCombosGetter: (
      _state,
      _getters,
      _rootState,
      _rootGetters
    ) => {
      let comboAllStopsObject = {}
      let attainedCombos = []
      // Use a Vuex getter to get an array of all combo bonuses defined for this rally in the rally book and use this to build an object that holds an
      // object for each defined combo bonus. This object then has a key/value pair for every combo constituent with a boolean value indicating
      // whether or not the bonus location is part of the route. For example {TEST1: {TEXLINE: false, CHILDRESS: true,} TEST2: {CHILDRESS: true, ANTHONY: true}}
      for (let index in _getters.BonusLocationsCombosGetter) {
        let comboStopsArray =
          _getters.BonusLocationsCombosGetter[index].combostops.split(',')
        let comboStopsObject = {}
        for (let indexStops in comboStopsArray) {
          // check if combo stop is part of the RouteStops object: set to true if found, otherwise false
          comboStopsObject[comboStopsArray[indexStops]] =
            _rootGetters['moduleRouteStops/RouteStopsByName'](
              comboStopsArray[indexStops]
            ).name !== undefined
        }
        comboAllStopsObject[_getters.BonusLocationsCombosGetter[index].name] =
          comboStopsObject
      }
      // iterate over the object to determine which combo has been attained. A combo is attained if all of its constituents have a 'true' value.
      for (let index in comboAllStopsObject) {
        let result = true
        for (let index2 in comboAllStopsObject[index]) {
          if (comboAllStopsObject[index][index2] === false) result = false
        }
        comboAllStopsObject[index].complete = result
      }
      for (let index in comboAllStopsObject) {
        if (comboAllStopsObject[index].complete === true) {
          attainedCombos.push(_getters.BonusLocationsByNameGetter(index))
        }
      }
      return assignPrototype(attainedCombos)
    },

    /**
     * @function BonusLocationsPointsBySymbolGetter
     * @author Patrick Nijsters
     * @description Vuex *getter* that returns the total point value for a group of non-mappable bonuses (category='N') that all share the symbol name (and can this be considered to be part of a specific sub-category of non-mappable bonuses: call-in, tracking, rest and other)
     * @memberof module:moduleBonusLocations
     * @param {Object} _state Vuex state object
     * @param {rest|tracking|call-in|other} _symbol String with the symbol that defines the symbol which to categorize the non-mappable bonus by for the purposes of this function
     * @returns {Number} The total point value of the non-mappable bonuses that share the provided input symbol
     * @example BonusLocationsPointsBySymbolGetter('rest') => 4000
     */
    BonusLocationsPointsBySymbolGetter: (_state) => (_symbol) => {
      return _state.BonusLocations.filter(
        (bonusLocation) =>
          bonusLocation.category === 'N' && bonusLocation.symbol === _symbol
      ).reduce((sum, current) => {
        return sum + Number(current.points)
      }, 0)
    },

    /**
     * @function BonusLocationsCombosGetter
     * @author Patrick Nijsters
     * @description Vuex **getter* that returns an array of only combo BonusLocation objects. The return array contains BonusLocations objects for those bonuses where category=TC|AC|DC (timerestricted combo, daylight only combo or all day combo)
     * @memberof module:moduleBonusLocations
     * @param {Object} _state Vuex state object
     * @returns {Array.<BonusLocationsPrototype>} Array of combo BonusLocations objects
     */
    BonusLocationsCombosGetter: (_state) => {
      let result = _state.BonusLocations.filter(
        (bonuslocation) =>
          (bonuslocation.category === 'TC') |
          (bonuslocation.category === 'AC') |
          (bonuslocation.category === 'DC')
      )
      if (result === undefined) return 0
      return assignPrototype(result)
    },

    /**
     * @function BonusLocationsCombosConstituentsGetter
     * @author Patrick Nijsters
     * @description Vuex *getter* that outputs an array of combo constituent BonusLocations objects. The return array contains BonusLocations objects for those bonuses where !combostops
     * @memberof module:moduleBonusLocations
     * @param {Object} _state Vuex state object
     * @returns {Array.<BonusLocationsPrototype>}
     * @todo NEEDS CLEANUP FOR EFFICIENCY
     */
    BonusLocationsCombosConstituentsGetter: (_state) => {
      let output = []
      let counter = 0
      let combos = _state.BonusLocations.filter(
        (bonuslocation) =>
          (bonuslocation.category === 'TC') |
          (bonuslocation.category === 'AC') |
          (bonuslocation.category === 'DC')
      )
      for (let index in combos) {
        let stops = combos[index].combostops.split(',')
        for (let stopindex in stops) {
          let bonuslocation = _state.BonusLocations.find(
            (bonusLocation) => bonusLocation.name === stops[stopindex]
          )
          let result = { ...bonuslocation }
          result.index = counter
          result.name = stops[stopindex]
          result.comboname = combos[index].name
          output.push(result)
          counter = counter + 1
        }
      }
      return assignPrototype(output)
    },

    /**
     * @function BonusLocationsWithProximityGetter
     * @author Patrick Nijsters
     * @description Vuex **getter** that returns an array of BonusLocations objects for each bonus location that has a non-empty proximity field. The actual proximity calculation is NOT done in this function. This function assumes the proximity calculation has already been run and the outcomes have been posted in the 'proximity' field of each bonus location
     * @memberof module:moduleBonusLocations
     * @param {Object} _state Vuex state object
     * @returns {Array.<BonusLocationsPrototype>}
     */
    BonusLocationsWithProximityGetter: (_state) => {
      let result = _state.BonusLocations.filter(
        (bonuslocation) =>
          bonuslocation.proximity !== undefined &&
          bonuslocation.proximity !== null &&
          bonuslocation.proximity !== ''
      )
      if (result === undefined) return null
      return assignPrototype(result)
    },

    /**
     * @function
     * @author Patrick Nijsters
     * @description Vuex **getter** that returns the count of BonusLocations objects excluding the non-mappable and start/end bonuses (category !=N & !=S)
     * @memberof module:moduleBonusLocations
     * @param {Object} _state Vuex state object
     * @returns {Number}
     */
    BonusLocationsCountGetter: (_state) => {
      return _state.BonusLocations.filter(
        (bonusLocation) =>
          bonusLocation.category !== 'S' && bonusLocation.category !== 'N'
      ).length
    },

    /**
     * @function BonusLocationsByNameGetter
     * @author Patrick Nijsters
     * @description Vuex **getter** that returns the BonusLocations object for the bonus which name equals the one provided as a parameter
     * @memberof module:moduleBonusLocations
     * @param {Object} _state Vuex state object
     * @param {String} _searchLocation
     * @returns {BonusLocationsPrototype}
     */
    BonusLocationsByNameGetter: (_state) => (_searchLocation) => {
      if (_searchLocation === 'NOPOINTS') return null
      let result = _state.BonusLocations.find(
        (bonusLocation) => bonusLocation.name === _searchLocation
      )
      if (!result) {
        console.error(
          `BonusLocationsByNameGetter: could not find location in Vuex state store ${_searchLocation}`
        )
        return null
      }
      result.__proto__ = BonusLocationsPrototype.prototype
      return result
    },

    /**
     * @function BonusLocationsNonRidingOnlyGetter
     * @author Patrick Nijsters
     * @description Vuex **getter** that returns a by bonus name sorted array of non-mappable bonus BonusLocations objects
     * @memberof module:moduleBonusLocations
     * @param {Object} _state Vuex state object
     * @returns {Array.<BonusLocationsPrototype>}
     */
    BonusLocationsNonRidingOnlyGetter: (_state) => {
      let result = _state.BonusLocations.filter(
        (bonusLocation) => bonusLocation.category === 'N'
      ).sort((a, b) => (a.name > b.name ? 1 : -1))
      return assignPrototype(result)
    },

    /**
     * @function BonusLocationsRidingOnlyGetter
     * @author Patrick Nijsters
     * @description Vuex **getter** that returns a by bonus name sorted array of riding bonus BonusLocation objects
     * @memberof module:moduleBonusLocations
     * @param {Object} _state Vuex state object
     * @returns {Array.<BonusLocationsPrototype>}
     */
    BonusLocationsRidingOnlyGetter: (_state) => {
      let result = _state.BonusLocations.filter(
        (bonusLocation) =>
          bonusLocation.category !== 'S' &&
          bonusLocation.category !== 'N' &&
          bonusLocation.category !== 'AC' &&
          bonusLocation.category !== 'DC' &&
          bonusLocation.category !== 'TC'
      ).sort((a, b) => (a.name > b.name ? 1 : -1))
      return assignPrototype(result)
    },

    /**
     * @function BonusLocationsRidingNamesOnlyGetter
     * @author Patrick Nijsters
     * @description Vuex **getter** that returns an alphabetically by bonus name sorted array of string values. One string value for each riding bonus location (excluding start/end)
     * @memberof module:moduleBonusLocations
     * @param {Object} _state Vuex state object
     * @returns {Array.<String>}
     */
    BonusLocationsRidingNamesOnlyGetter: (_state) => {
      return _state.BonusLocations.filter(
        (bonusLocation) =>
          bonusLocation.category !== 'S' &&
          bonusLocation.category !== 'N' &&
          bonusLocation.category !== 'AC' &&
          bonusLocation.category !== 'DC' &&
          bonusLocation.category !== 'TC'
      )
        .map((bonuslocation) => {
          return bonuslocation.name
        })
        .sort((a, b) => (a > b ? 1 : -1))
    },

    /**
     * @function BonusLocationsExcludingNonRidingGetter
     * @author Patrick Nijsters
     * @description Vuex **getter** that returns an array of all BonusLocations objects except non-mappable bonuses (category !=N)
     * @memberof module:moduleBonusLocations
     * @param {Object} _state Vuex state object
     * @returns {Array.<BonusLocationsPrototype>} A sorted array of all regular, combo and start/end BonusLocations objects excluding the non-mappable (category 'N') bonuses
     */
    BonusLocationsExcludingNonRidingGetter: (_state) => {
      const result = _state.BonusLocations.filter(
        (bonusLocation) => bonusLocation.category !== 'N'
      ).sort((a, b) => (a.name > b.name ? 1 : -1))
      return assignPrototype(result)
    },

    /**
     * @function BonusLocationsByCategoryGetter
     * @author Patrick Nijsters
     * @description Vuex **getter** that returns an array of BonusLocations objects with each bonus being part of the category provided as input parameter
     * @memberof module:moduleBonusLocations
     * @param {Object} _state Vuex state object
     * @param {D|A|T|S|N|DC|AC|TC} _category
     * @returns {Array.<BonusLocationsPrototype>}
     */
    BonusLocationsByCategoryGetter: (_state) => (_category) => {
      const result = _state.BonusLocations.filter(
        (bonusLocation) => bonusLocation.category === _category
      )
      return assignPrototype(result)
    },

    /**
     * @function BonusLocationsRidingOnlyByValueRangeGetter
     * @author Patrick Nijsters
     * @description Vuex **getter** that returns an array of riding only BonusLocations objects that have a point value within the provided input range
     * @memberof module:moduleBonusLocations
     * @param {Object} _state Vuex state object
     * @param {Number} _minimum
     * @param {Number} _maximum
     * @returns {Array.<BonusLocationsPrototype>}
     */
    BonusLocationsRidingOnlyByValueRangeGetter:
      (_state) => (_minimum, _maximum) => {
        const result = _state.BonusLocations.filter(
          (bonusLocation) =>
            Number(bonusLocation.points) >= Number(_minimum) &&
            Number(bonusLocation.points) <= Number(_maximum) &&
            bonusLocation.category !== 'S' &&
            bonusLocation.category !== 'N' &&
            bonusLocation.category !== 'AC' &&
            bonusLocation.category !== 'DC' &&
            bonusLocation.category !== 'TC'
        )
        return assignPrototype(result)
      }
  }
}

/**
 * @description Vuex module for Bonus Locations
 */
export default moduleBonusLocations
