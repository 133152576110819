import { DateTime } from 'luxon'
import { v4 as uuidv4 } from 'uuid'
import store from '@/store'

/**
 * @class
 * @author Patrick Nijsters
 * @global
 * @property {UUIDv4} routeid Globally unique identifier for this route
 * @property {String} description Short description of the route
 * @property {Number} modified The datetime object for when this route was last modified
 */
export function RoutesPrototype(
  routeid = null,
  description = null,
  modified = null
) {
  this.routeid = routeid
  this.description = description
  this.modified = modified
}

/**
 * @description Initializes a new route by setting its globally unique UUIDv4, setting the modified date to now() and auto generating the sequenced name
 * @author Patrick Nijsters
 * @returns {Void}
 */
RoutesPrototype.prototype.initializeRoute = function () {
  this.routeid = uuidv4()
  this.modified = DateTime.now().toISO()
  let maximumroutenumber = 0
  for (let index in store.state.moduleRoutes.Routes) {
    maximumroutenumber = Math.max(
      maximumroutenumber,
      Number(store.state.moduleRoutes.Routes[index].description.split('#')[1])
    )
  }
  this.description = `Route #${Number(maximumroutenumber) + 1}`
}

/**
 * @description This function takes the Luxon DateTime object for the routestop and outputs a specifically formatted string for display
 * @author Patrick Nijsters
 * @returns {String}
 */
RoutesPrototype.prototype.getFormattedtime = function () {
  return DateTime.fromISO(this.modified).toFormat('MM/dd HH:mm ZZZZ')
}
