/**
 * @module moduleLegPlan
 */

const moduleLegPlan = {
  namespaced: true,
  state: {
    LegPlan: []
  },
  mutations: {
    /**
     * @function LegPlanClearMutation
     * @description Vuex **mutation** that clears the legplan from the Vuex state store
     * @author Patrick Nijsters
     * @memberof module:moduleLegPlan
     * @param {Object} _state Vuex state object
     * @returns {Void}
     */
    LegPlanClearMutation: (_state) => {
      _state.LegPlan = []
    },

    /**
     * @function LegPlanUpdateMutation
     * @description Vuex **mutation** that stores the updated legplan in the Vuex state store
     * @author Patrick Nijsters
     * @param {Object} _state Vuex state object
     * @param {LegPlanPrototype} _legplan
     * @returns {Void}
     */
    LegPlanUpdateMutation: (_state, _legplan) => {
      _state.LegPlan = []
      _state.LegPlan.push(_legplan)
    }
  },
  actions: {
    /**
     * @function LegPlanUpdateAction
     * @description Vuex **acction** that uses a Vuex mutation to update the legplan in the Vuex state store
     * @author Patrick Nijsters
     * @memberof module:moduleLegPlan
     * @param {VuexContext} _context Vuex context object
     * @param {LegPlanPrototype} _legplan
     * @see module:moduleLegPlan.LegPlanUpdateMutation
     * @returns {Void}
     */
    LegPlanUpdateAction: (_context, _legplan) => {
      _context.commit(
        'LegPlanUpdateMutation',
        JSON.parse(JSON.stringify(_legplan))
      )
    },

    /**
     * @function LegPlanClearAction
     * @description Vuex **action** that uses a Vuex mutation to clear the legplan from the Vuex state store
     * @author Patrick Nijsters
     * @memberof module:moduleLegPlan
     * @param {VuexContext} _context Vuex context object
     * @returns {Void}
     */
    async LegPlanClearAction(_context) {
      _context.commit('LegPlanClearMutation')
    }
  }
}

/**
 * @description Vuex module for Legplan
 */
export default moduleLegPlan
