/**
 * @module moduleUser
 */
/**
 * @typedef {Object} VuexContext
 * @global
 * @property {Object} state Same as store.state, or local state if in modules
 * @property {Object} rootState Same as store.state, only in modules
 * @property {Object} commit Same as store.commit
 * @property {Object} dispatch Same as store.dispatch
 * @property {Object} getters Same as store.getters, or local getters if in modules
 * @property {Object} rootGetters Same as store.getters, only in modules
 */

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import modulePreferences from './modulePreferences'
import moduleBonusLocations from './moduleBonusLocations'
import moduleRallies from './moduleRallies'
import moduleRouteStops from './moduleRouteStops'
import moduleNotifications from './moduleNotifications'
import moduleLegPlan from './moduleLegPlan'
import moduleRouteService from './moduleRouteService'
import moduleRoutes from './moduleRoutes'
import { UserProfilePrototype } from '../components/prototypes/userprofile'

Vue.use(Vuex)

const Firebase = require('@/firebaseConfig.js')

// handle page reload
Firebase.auth.onAuthStateChanged((_user) => {
  if (_user) {
    store.commit('moduleUser/SetCurrentUser', _user)
    store.dispatch('moduleUser/UserProfileReadAction')
  }
})

const moduleUser = {
  namespaced: true,
  state: {
    CurrentUser: null,
    UserProfile: {}
  },
  mutations: {
    /**
     * @function SetCurrentUser
     * @author Patrick Nijsters
     * @description Vuex **mutattion** that updates the User state
     * @memberof module:moduleUser
     * @param {VuexContext} _state Vuex state object
     * @param {*} _user Google Firebase User Object that is returned from Firebase authentication
     * @returns {Void}
     */
    SetCurrentUser(_state, _user) {
      _state.CurrentUser = _user
    },

     /**
     * @function SetUserProfile
     * @author Patrick Nijsters
     * @description Vuex **mutattion** that updates the Profile state
     * @memberof module:moduleUser
     * @param {VuexContext} _state Vuex state object
     * @param {UserProfilePrototype} _profile Userprofile object
     * @returns {Void}
     */
    SetUserProfile(_state, _profile) {
      _state.UserProfile = _profile
    },

    /**
     * @function UserProfileSetActiveRallyMutation
     * @author Patrick Nijsters
     * @description Vuex **mutation** that updates the activeRallyID in the Userprofile object
     * @memberof module:moduleUser
     * @param {VuexContext} _state 
     * @param {String} _rallyid 
     * @returns {Void}
     */
    UserProfileSetActiveRallyMutation(_state, _rallyid) {
      _state.UserProfile.activerallyid = _rallyid
    },

    /**
     * @function UserProfileSetActiveRouteMutation
     * @author Patrick Nijsters
     * @description Vuex **mutation** that updates the activeRouteID in the Userprofile object
     * @memberof module:moduleUser
     * @param {VuexContext} _state 
     * @param {String} _rallyid 
     * @returns {Void}
     */
    UserProfileSetActiveRouteMutation(_state, _routeid) {
      _state.UserProfile.activerouteid = _routeid
    }
  },
  actions: {
    /**
     * @function UserProfileReadAction
     * @author Patrick Nijsters
     * @description Vuex **action** which user the Firestore API to read the UserProfile object into the state store
     * @memberof module:moduleUser
     * @async
     * @param {VuexContext} param0 Vuex state 
     * @returns {Void}
     * @requires firebaseConfig.js
     */
    async UserProfileReadAction({ commit, state }) {
      if (state.CurrentUser === null) return
      await Firebase.db
        .collection('users')
        .doc(state.CurrentUser.uid)
        .get()
        .then((result) => {
          commit('SetUserProfile', result.data())
        })
        .catch((error) => {
          console.error(error)
        })
    },

    /**
     * @function UserProfileSetActiveRallyAction
     * @author Patrick Nijsters
     * @description Vuex **action** which
     * 1. calls the Firestore API to overwrite the activeRallyID in the Userprofile Firestore database
     * 2. calls a Vuex mutation to overwrite the activeRallyID in the Userprofile state store
     * @memberof module:moduleUser
     * @async
     * @param {VuexContext} param0 
     * @param {UUIDv4} _rallyid UUIDv4 corresponding to the active/selected rally
     * @requires firebaseConfig.js
     * @returns {Void}
     */
    async UserProfileSetActiveRallyAction({ commit, state }, _rallyid) {
      if (state.CurrentUser === null) return
      commit('UserProfileSetActiveRallyMutation', _rallyid)
      await Firebase.db.collection('users').doc(state.CurrentUser.uid).set(
        {
          activerallyid: _rallyid
        },
        { merge: true }
      )
    },

     /**
     * @function UserProfileSetActiveRouteAction
     * @author Patrick Nijsters
     * @description Vuex **action** which
     * 1. calls the Firestore API to overwrite the activeRouteID in the Userprofile Firestore database
     * 2. calls a Vuex mutation to overwrite the activeRouteID in the Userprofile state store
     * @memberof module:moduleUser
     * @async
     * @param {VuexContext} param0 
     * @param {UUIDv4} _rallyid UUIDv4 corresponding to the active/selected route
     * @requires firebaseConfig.js
     * @returns {Void}
     */
    async UserProfileSetActiveRouteAction({ commit, state }, _routeid) {
      if (state.CurrentUser === null) return
      commit('UserProfileSetActiveRouteMutation', _routeid)
      await Firebase.db.collection('users').doc(state.CurrentUser.uid).set(
        {
          activerouteid: _routeid
        },
        { merge: true }
      )
    },

    /**
     * @function UserProfileClearAction
     * @author Patrick Nijsters
     * @description Vuex **action** which calls a Vuex mutation that clears ('deletes') Userprofile fields from the Vuex state store only (the function does not empty the Firestore corresponding collection)
     * @memberof module:moduleUser
     * @async
     * @param {VuexContext} _context Vuex context {state, rootState, commit, dispatch, getters, rootGetters}
     * @see module:moduleUser~UserProfileClearMutation
     * @returns {Void}
     */    
    async UserProfileClearAction({ commit }) {
      commit('SetUserProfile', {})
    },

       /**
     * @function CurrentUserClearAction
     * @author Patrick Nijsters
     * @description Vuex **action** which calls a Vuex mutation that clears ('deletes') User fields from the Vuex state store only (the function does not empty the Firestore corresponding collection)
     * @memberof module:moduleUser
     * @async
     * @param {VuexContext} _context Vuex context {state, rootState, commit, dispatch, getters, rootGetters}
     * @see module:moduleUser~CurrentUserClearMutation
     * @returns {Void}
     */   
    async CurrentUserClearAction({ commit }) {
      commit('SetCurrentUser', null)
    }
  },
  getters: {}
}

const store = new Vuex.Store({
  modules: {
    moduleUser: moduleUser,
    modulePreferences: modulePreferences,
    moduleBonusLocations: moduleBonusLocations,
    moduleRallies: moduleRallies,
    moduleRouteStops: moduleRouteStops,
    moduleNotifications: moduleNotifications,
    moduleLegPlan: moduleLegPlan,
    moduleRouteService: moduleRouteService,
    moduleRoutes: moduleRoutes
  },
  plugins: [
    createPersistedState({
      key: 'ld-rally-ride-planner',
      reducer(_state) {
        if (_state.CurrentUser === null) {
          return {}
        }
        return _state
      }
    })
  ]
})

export default store
