import store from '@/store'
export function parseTimeRestrictions(_routestop) {
  let restrictions = store.getters[
    'moduleBonusLocations/BonusLocationsByNameGetter'
  ](_routestop.name).timerestrictions
  if (!restrictions) return []
  const dayMap = new Map()
  dayMap.set('mon', 1)
  dayMap.set('tue', 2)
  dayMap.set('wed', 3)
  dayMap.set('thu', 4)
  dayMap.set('fri', 5)
  dayMap.set('sat', 6)
  dayMap.set('sun', 7)
  restrictions = restrictions.toLowerCase()
  restrictions = restrictions.replace(
    'ss',
    store.getters['moduleBonusLocations/BonusLocationsByNameGetter'](
      _routestop.name
    ).sunset
  )
  restrictions = restrictions.replace(
    'sr',
    store.getters['moduleBonusLocations/BonusLocationsByNameGetter'](
      _routestop.name
    ).sunrise
  )

  let countRestrictionsParts = 1
  if (restrictions.match(/\|/g))
    countRestrictionsParts = restrictions.match(/\|/g).length + 1
  let restrictionArray = []
  for (
    let iterateRestrictions = 1;
    iterateRestrictions <= countRestrictionsParts;
    iterateRestrictions++
  ) {
    let partRestriction = ''
    if (restrictions.indexOf('|') > -1) {
      partRestriction = restrictions.substr(0, restrictions.indexOf('|'))
    } else {
      partRestriction = restrictions
    }
    restrictions = restrictions.substr(
      partRestriction.length + 1,
      restrictions.length
    )
    let partDate = ''
    let atPosition = partRestriction.indexOf('@')
    if (atPosition === -1) {
      partDate = 'mon-sun'
    } else {
      partDate = partRestriction.substr(0, atPosition)
    }
    let partTime = partRestriction.substr(
      atPosition + 1,
      partRestriction.length
    )
    let countDaysParts = 1
    if (partDate.match(/,/g)) countDaysParts = partDate.match(/,/g).length + 1
    let countTimeParts = 1
    if (partTime.match(/,/g)) countTimeParts = partTime.match(/,/g).length + 1

    let subpartDate = ''
    for (let iterateDay = 1; iterateDay <= countDaysParts; iterateDay++) {
      if (partDate.indexOf(',') > -1) {
        subpartDate = partDate.substr(0, partDate.indexOf(','))
      } else {
        subpartDate = partDate
      }
      partDate = partDate.substr(subpartDate.length + 1, partDate.length)

      let subpartDateStart = ''
      let subpartDateEnd = ''
      if (subpartDate.match(/-/g)) {
        subpartDateStart = subpartDate.substr(0, subpartDate.indexOf('-'))
        subpartDateEnd = subpartDate.substr(
          subpartDate.indexOf('-') + 1,
          subpartDate.length
        )
      } else {
        subpartDateStart = subpartDate
        subpartDateEnd = subpartDate
      }

      partTime = partRestriction.substr(atPosition + 1, partRestriction.length)
      let subpartTime = ''
      for (let iterateTime = 1; iterateTime <= countTimeParts; iterateTime++) {
        if (partTime.indexOf(',') > -1) {
          subpartTime = partTime.substr(0, partTime.indexOf(','))
        } else {
          subpartTime = partTime
        }
        partTime = partTime.substr(subpartTime.length + 1, partTime.length)
        let subpartTimeStart = ''
        let subpartTimeEnd = ''

        if (subpartTime.match(/-/g)) {
          subpartTimeStart = subpartTime.substr(0, subpartTime.indexOf('-'))
          subpartTimeEnd = subpartTime.substr(
            subpartTime.indexOf('-') + 1,
            subpartTime.length
          )
        } else {
          subpartTimeStart = subpartTime
          subpartTimeEnd = subpartTime
        }
        restrictionArray.push({
          date_start: dayMap.get(subpartDateStart),
          date_end: dayMap.get(subpartDateEnd),
          time_start: convertTimeToSeconds(subpartTimeStart),
          time_end: convertTimeToSeconds(subpartTimeEnd)
        })
      }
    }
  }
  return restrictionArray
}

function convertTimeToSeconds(_time) {
  let convertedTime = 0
  if (_time.indexOf(':') > -1) {
    //hours and minutes
    let worktime = _time.replace(/p|a/gi, '')
    convertedTime =
      Number(worktime.substr(0, _time.indexOf(':'))) * 60 +
      Number(worktime.substring(_time.indexOf(':') + 1, worktime.length))
  } else {
    //only hours
    convertedTime = Number(_time.substr(0, _time.length - 1)) * 60
  }
  if (_time.match(/p/g)) {
    //add 12 hrs for PM
    convertedTime = convertedTime + 12 * 60
  }
  return convertedTime
}

export function evaluateTimeRestrictions(_datetime, _restrictions) {
  if (!_restrictions || _restrictions.length === 0) return false
  let foundTrue = false
  let validateDay = _datetime.toFormat('c').toLowerCase()
  let validateTime =
    Number(_datetime.toFormat('H')) * 60 + Number(_datetime.toFormat('m'))

  for (let index in _restrictions) {
    if (
      //single specific time 'deadline'
      _restrictions[index].date_start === _restrictions[index].date_end &&
      _restrictions[index].time_start === _restrictions[index].time_end &&
      validateDay <= _restrictions[index].date_start &&
      validateTime <= _restrictions[index].time_start
    )
      foundTrue = true
    if (
      // time range
      validateDay >= _restrictions[index].date_start &&
      validateDay <= _restrictions[index].date_end &&
      validateTime >= _restrictions[index].time_start &&
      validateTime <= _restrictions[index].time_end
    )
      foundTrue = true
  }
  return foundTrue
}
