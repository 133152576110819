import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import * as VueGoogleMaps from 'vue2-google-maps'
import IdleVue from 'idle-vue'
const Firebase = require('./firebaseConfig.js')

let app
Firebase.auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      el: '#app',
      router,
      store,
      vuetify,
      render: (h) => h(App)
    })
  }
})

Vue.prototype.$http = axios
Vue.config.productionTip = false

Vue.use(IdleVue, {
  eventEmitter: app,
  // idleTime in millseconds: 1 hour = 360000
  idleTime: 360000,
  store,
  startAtIdle: false
})
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCh7rsvoyAy1xxFEW0WcFkVNw3hCFKSPUw',
    libraries: 'places'
  },
  installComponents: true
})
