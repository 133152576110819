/**
 * @module modulePreferences
 */

import { db } from '@/firebaseConfig.js'

const modulePreferences = {
  namespaced: true,
  state: {
    Preferences: {}
  },
  mutations: {
    /**
     * @function PreferencesClearMutation
     * @author Patrick Nijsters
     * @description Vuex **mutation** that deletes all preferences from the Vuex state store
     * @memberof module:modulePreferences
     * @param {Object} _state Vuex state object
     * @returns {Void}
     */
    PreferencesClearMutation: (_state) => {
      _state.Preferences = {}
    },

    /**
     * @function PreferencesReadMutation
     * @author Patrick Nijsters
     * @description Vuex **mutation** that stores all preferences passed to it in the Vuex state store
     * @memberof module:modulePreferences
     * @param {Object} _state Vuex state object
     * @param {Preferences} _preferences Preferences object
     * @returns {Void}
     */
    PreferencesReadMutation: (_state, _preferences) => {
      _state.Preferences = { ..._preferences }
    }
  },
  actions: {
    /**
     * @function PreferencesUpdateAction
     * @author Patrick Nijsters
     * @description Vuex **action* wich
     * 1. calls the Firestore API to store the passed preferences into the Firestore database
     * 2. calls a Vuex mutation to clear the currently stored preferences from the Vuex state store
     * 3. calls a Vuex mutation to store the passed preferences into the Vuex state store
     * @memberof module:modulePreferences
     * @param {VuexContext} _context Vuex context {state, rootState, commit, dispatch, getters, rootGetters}
     * @param {Preferences} _preferences The full Preferences object
     * @returns {Void}
     * @see module:modulePreferences.PreferencesClearMutation
     * @see module:modulePreferences.PreferencesReadMutation
     * @example PreferencesUpdateAction(
     *  {VuexContext object},
     *  {Preferences object},
     *  )
     *
     */
    async PreferencesUpdateAction(_context, _preferences) {
      await db
        .collection(
          `users/${_context.rootState.moduleUser.CurrentUser.uid}/preferences`
        )
        .doc('rallies')
        .set(JSON.parse(JSON.stringify(_preferences)))
      _context.commit('PreferencesClearMutation')
      _context.commit('PreferencesReadMutation', _preferences)
    },

    /**
     * @function PreferencesReadAction
     * @author Patrick Nijsters
     * @description Vuex **action** which
     * 1. clears the current preferences from the Vuex state store with a Vuex mutation
     * 2. uses the Firestore API to read all preferences from the specified collection into the Vuex state by using a Vuex mutation
     * @memberof module:modulePreferences
     * @param {VuexContext} _context Vuex context {state, rootState, commit, dispatch, getters, rootGetters}
     * @returns {Void}
     * @see module:modulePreferences.PreferencesClearMutation
     * @see module:modulePreferences.PreferencesReadMutation
     * @example PreferencesReadAction({VuexContext object})
     */
    async PreferencesReadAction(_context) {
      _context.commit('PreferencesClearMutation')
      let result = await db
        .collection(
          `users/${_context.rootState.moduleUser.CurrentUser.uid}/preferences`
        )
        .doc('rallies')
        .get()
      _context.commit('PreferencesReadMutation', result.data())
    },

    /**
     * @function PreferencesClearAction
     * @author Patrick Nijsters
     * @description Vuex **action** which calls a Vuex mutation that clears ('deletes') all preferences from the Vuex state store only (the function does not empty the Firestore corresponding collection)
     * @memberof module:modulePreferences
     * @param {VuexContext} _context Vuex context {state, rootState, commit, dispatch, getters, rootGetters}
     * @returns {Void}
     * @see module:modulePreferences.PreferencesClearMutation
     * @example PreferencesClearAction({VuexContext object})
     */
    PreferencesClearAction: (_context) => {
      _context.commit('PreferencesClearMutation')
    }
  },
  getters: {}
}

/**
 * @description Vuex module for Preferences
 */
export default modulePreferences
