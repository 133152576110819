import store from '@/store'
import { DateTime } from 'luxon'
import { v4 as uuidv4 } from 'uuid'

/**
 * @class
 * @author Patrick Nijsters
 * @property {UUIDv4} rallyid Globally unique identifier for the rally
 * @property {String} rallyname Name of the rally
 * @property {String} description Description of the rally
 * @property {Number} legid The numerical order of this leg
 * @property {ISO8601} createdon The datetime object for when this rally was created in this application
 * @property {UUIDv4} createdby Reference to the UUIDv4 identifier of the person that created this rally
 * @property {String} createdname Name of the person that created this rally
 * @property {String} garmincategory The name of the category under which these bonuses from this rally would show on a Garmin satnav device
 * @property {String} rallyurl The URL pointing to the website where the relevant rally information is published
 * @property {Number} onclockplanningtime=0 The time, in minutes, that is spent planning for this rally after the rally has started
 * @property {Number} goalpercentage=100 The target percentage multiplyer that determines how many points over the minimum required bonus points
 * @property {Number} minimumbonuspoints=0 The minimum number of points required to be considered a finished of this rally
 * @property {Number} penaltywindow=0 The length of the penalty window, in minutes, that starts after the projected finish time of the rally
 * @property {Number} penaltypoints=0 The number of points per minute lost for every minute in the penalty window
 * @property {Object} start The start location of the rally
 * @property {ISO8601} start.datetime The datetime Object of the start of the rally
 * @property {Number} start.latitude The latitude of the bonus location in decimal degrees format (https=//en.wikipedia.org/wiki/Decimal_degrees)
 * @property {Number} start.longitude The longitude of the bonus location in decimal degrees format (https=//en.wikipedia.org/wiki/Decimal_degrees)
 * @property {String} start.sunset Time of sunset for this bonus location on the date of the start of the rally in hh=mm format
 * @property {String} start.sunrise Time of sunrise for this bonus location on the date of the start of the rally in hh=mm format
 * @property {String} start.timezoneid The timezone of the location in its most descriptive format like "Americas/Chicago"
 * @property {Object} end The end or finish location of the rally
 * @property {ISO8601} end.datetime The datetime Object of the end of the rally
 * @property {Number} end.latitude The latitude of the bonus location in decimal degrees format (https=//en.wikipedia.org/wiki/Decimal_degrees)
 * @property {Number} end.longitude The longitude of the bonus location in decimal degrees format (https=//en.wikipedia.org/wiki/Decimal_degrees)
 * @property {String} end.sunset Time of sunset for this bonus location on the date of the start of the rally in hh=mm format
 * @property {String} end.sunrise Time of sunrise for this bonus location on the date of the start of the rally in hh=mm format
 * @property {String} end.timezoneid The timezone of the location in its most descriptive format like "Americas/Chicago"
 * @property {Object} tshirt The t-shirt size categories for the bonus point values for this rally as configured by the user
 * @property {Object} tshirt.small
 * @property {Number} tshirt.small.count=0 The number of items in this catogory
 * @property {Number} tshirt.small.maximum=0 The maximum point value of any bonus in this category
 * @property {Number} tshirt.small.percentage=0 The percentage of bonuses mapped into this tshirt category
 * @property {Object} tshirt.medium
 * @property {Number} tshirt.medium.count=0 The number of items in this catogory
 * @property {Number} tshirt.medium.maximum=0 The maximum point value of any bonus in this category
 * @property {Number} tshirt.medium.percentage=0 The percentage of bonuses mapped into this tshirt category
 * @property {Object} tshirt.large
 * @property {Number} tshirt.large.count=0 The number of items in this catogory
 * @property {Number} tshirt.large.maximum=0 The maximum point value of any bonus in this category
 * @property {Number} tshirt.large.percentage=0 The percentage of bonuses mapped into this tshirt category
 * @property {Object} tshirt.extralarge
 * @property {Number} tshirt.extralarge.count=0 The number of items in this catogory
 * @property {Number} tshirt.extralarge.maximum=0 The maximum point value of any bonus in this category
 * @property {Number} tshirt.extralarge.percentage=0 The percentage of bonuses mapped into this tshirt category
 * @property {Boolean} recalculateroute A flag to indicate some bonus location parameters have requiring an existing routeplan to be recalculated
 */
export function RalliesPrototype(
  rallyid = null,
  rallyname = null,
  description = null,
  legid = null,
  createdon = null,
  createdby = null,
  createdname = null,
  rallyurl = null,
  onclockplanningtime = null,
  goalpercentage = null,
  minimumbonuspoints = null,
  garmincategory = null,
  penaltywindow = null,
  penaltypoints = null,
  start = null,
  end = null,
  tshirt = null,
  recalculateroute = null
) {
  this.rallyid = rallyid
  this.rallyname = rallyname
  this.description = description
  this.legid = legid
  this.createdon = createdon
  this.createdby = createdby
  this.createdname = createdname
  this.rallyurl = rallyurl
  this.onclockplanningtime = onclockplanningtime
  this.goalpercentage = goalpercentage
  this.minimumbonuspoints = minimumbonuspoints
  this.garmincategory = garmincategory
  this.penaltywindow = penaltywindow
  this.penaltypoints = penaltypoints
  this.start = start
    ? start
    : {
        datetime: null,
        latitude: null,
        longitude: null,
        sunset: null,
        sunrise: null,
        timezoneid: null
      }
  this.end = end
    ? end
    : {
        datetime: null,
        latitude: null,
        longitude: null,
        sunset: null,
        sunrise: null,
        timezoneid: null
      }
  ;(this.tshirt = tshirt
    ? tshirt
    : {
        small: { maximum: null, count: null, percentage: null },
        medium: { maximum: null, count: null, percentage: null },
        large: { maximum: null, count: null, percentage: null },
        extralarge: {
          maximum: null,
          count: null,
          percentage: null
        }
      }),
    (this.recalculateroute = recalculateroute)
}

RalliesPrototype.prototype.initializeRally = function () {
  this.rallyid = uuidv4()
  this.createdon = DateTime.now().toISO()
  this.createdby = store.state.moduleUser.CurrentUser.uid
  this.createdname = store.state.moduleUser.UserProfile.name
  store.state.moduleUser.UserProfile.name
}

/**
 *
 * @returns {String}
 */
RalliesPrototype.prototype.getRallyduration = function () {
  const RALLYDURATION = DateTime.fromISO(this.end.datetime)
    .setZone(this.end.timezoneid)
    .diff(DateTime.fromISO(this.start.datetime).setZone(this.start.timezoneid))
    .as('hours')
  if (Math.abs(Math.round(RALLYDURATION / 24)) < 0) {
    return `${RALLYDURATION % 24} hours`
  }
  if (Math.abs(Math.floor(RALLYDURATION / 24)) === 1) {
    return `${Math.abs(Math.floor(RALLYDURATION / 24))} day ${Math.abs(
      Math.round(RALLYDURATION % 24)
    )} hours`
  }

  return `${Math.abs(Math.floor(RALLYDURATION / 24))} days ${Math.abs(
    Math.round(RALLYDURATION % 24)
  )} hours`
}

/**
 *
 * @returns {URL}
 */
RalliesPrototype.prototype.getUrl = function () {
  try {
    new URL(this.rallyurl)
  } catch (_) {
    return new URL('https://ldrallyrideplanner.com/')
  }
  return new URL(this.rallyurl)
}
/**
 *
 * @param {Number} _small
 * @param {Number} _medium
 * @param {Number} _large
 * @param {Number} _extralarge
 * @returns {Boolean}
 */
RalliesPrototype.prototype.setTshirt = function (
  _small,
  _medium,
  _large,
  _extralarge
) {
  this.tshirt.small.maximum = _small
  this.tshirt.medium.maximum = _medium
  this.tshirt.large.maximum = _large
  this.tshirt.extralarge.maximum = _extralarge

  // make sure that all supplied values actually make sense
  if (this.tshirt.large.maximum > this.tshirt.extralarge.maximum)
    this.tshirt.large.maximum = this.tshirt.extralarge.maximum

  if (this.tshirt.large.maximum < 3) this.tshirt.large.maximum = 3
  if (this.tshirt.medium.maximum > this.tshirt.large.maximum)
    this.tshirt.medium.maximum = this.tshirt.large.maximum

  if (this.tshirt.medium.maximum < 2) this.tshirt.medium.maximum = 2

  if (this.tshirt.small.maximum > this.tshirt.medium.maximum)
    this.tshirt.small.maximum = this.tshirt.medium.maximum

  if (this.tshirt.small.maximum < 0) this.tshirt.small.maximum = 0

  // determine the bonus count per category for each category (small, medium, large, extralarge) based on the new numbers
  this.tshirt.small.count = store.getters[
    'moduleBonusLocations/BonusLocationsRidingOnlyByValueRangeGetter'
  ](0, this.tshirt.small.maximum).length
  this.tshirt.medium.count = store.getters[
    'moduleBonusLocations/BonusLocationsRidingOnlyByValueRangeGetter'
  ](this.tshirt.small.maximum + 1, this.tshirt.medium.maximum).length
  this.tshirt.large.count = store.getters[
    'moduleBonusLocations/BonusLocationsRidingOnlyByValueRangeGetter'
  ](this.tshirt.medium.maximum + 1, this.tshirt.large.maximum).length
  this.tshirt.extralarge.count = store.getters[
    'moduleBonusLocations/BonusLocationsRidingOnlyByValueRangeGetter'
  ](this.tshirt.large.maximum + 1, this.tshirt.extralarge.maximum).length

  // update the percentage fields for all categories
  let totalbonuslocation =
    store.getters['moduleBonusLocations/BonusLocationsRidingOnlyGetter'].length
  this.tshirt.small.percentage = Math.round(
    (100 * this.tshirt.small.count) / totalbonuslocation
  )
  this.tshirt.medium.percentage = Math.round(
    (100 * this.tshirt.medium.count) / totalbonuslocation
  )
  this.tshirt.large.percentage = Math.round(
    (100 * this.tshirt.large.count) / totalbonuslocation
  )
  this.tshirt.extralarge.percentage = Math.round(
    (100 * this.tshirt.extralarge.count) / totalbonuslocation
  )
  return true
}
