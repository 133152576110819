import Firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'

// firebase init goes here
const config = {
  apiKey: 'AIzaSyDrEZ7ZJ4Yv56nzHj4Dz3GK5_PmdAO_eCc',
  authDomain: 'rally-ride-planner.firebaseapp.com',
  projectId: 'rally-ride-planner',
  storageBucket: 'rally-ride-planner.appspot.com',
  messagingSenderId: '295923221593',
  appId: '1:295923221593:web:37ffb9535852a48b37dd48',
  measurementId: 'G-S7YKDVVLJ6',
  databaseURL: 'https://rally-ride-planner.firebaseio.com'
}
Firebase.initializeApp(config)

// firebase utils
const db = Firebase.firestore()
const auth = Firebase.auth()
const storage = Firebase.storage()
const cloudfunctions = Firebase.functions()
const CurrentUser = auth.CurrentUser

// firebase collections
const usersCollection = db.collection('users')

export { db, auth, storage, cloudfunctions, CurrentUser, usersCollection }
