/**
 * @module moduleRouteStops
 */

import { db } from '@/firebaseConfig.js'
import { RouteStopsPrototype } from '@/components/prototypes/routestops.js'

/**
 * @function assignPrototype
 * @description This function takes an array of routestop objects and ensures the array is returned with all objects correctly set to the RouteStopsPrototype prototype. If the correct prototype is already set on the objects in the array, the original array will be returned.
 * @author Patrick Nijsters
 * @memberof module:moduleRouteStops
 * @param {Array.<Object>} _array An array of routestop object that might or might not have the correct prototype set.
 * @returns {Array.<RouteStopsPrototype>}
 */
function assignPrototype(_array) {
  _array.forEach(
    (element) => (element.__proto__ = RouteStopsPrototype.prototype)
  )
  return _array
}

const moduleRouteStops = {
  namespaced: true,
  state: {
    RouteStops: []
  },
  mutations: {
    /**
     * @function RouteStopsReadMutation
     * @description Vuex **mutation** that pushed a new routestop into the Vuex state store
     * @author Patrick Nijsters
     * @memberof module:moduleRouteStops
     * @param {Object} _state Vuex state object
     * @param {RouteStopsPrototype} _routestop
     * @returns {Void}
     */
    RouteStopsReadMutation: (_state, _routestop) => {
      _state.RouteStops.push(_routestop)
    },

    /**
     * @function RouteStopsClearMutation
     * @description Vuex **mutation** that clears the entire Vuex state store
     * @author Patrick Nijsters
     * @memberof module:moduleRouteStops
     * @param {Object} _state Vuex state object
     * @returns {Void}
     */
    RouteStopsClearMutation: (_state) => {
      _state.RouteStops = []
    },

    /**
     * @function RouteStopsDeleteMutation
     * @description Vuex **mutation** that removes a specific routestop object from the Vuex state store
     * @author Patrick Nijsters
     * @memberof module:moduleRouteStops
     * @param {Object} _state Vuex state object
     * @param {RouteStopsPrototype} _routestop
     * @returns {Void}
     */
    RouteStopsDeleteMutation: (_state, _routestop) => {
      _state.RouteStops.splice(
        _state.RouteStops.findIndex(
          (element) => element.stopid === _routestop.stopid
        ),
        1
      )
    },

    /**
     * @function RouteStopsCreateUpdateMutation
     * @description Vuex **mutation** that either updates an existing routestop object (if found) or inserts a new routestop object (if not found)
     * @author Patrick Nijsters
     * @memberof module:moduleRouteStops
     * @param {Object} _state Vuex state object
     * @param {RouteStopsPrototype} _routestop
     * @returns {Void}
     */
    RouteStopsCreateUpdateMutation: (_state, _routestop) => {
      const routestop = _state.RouteStops.find(
        (routestops) => routestops.stopid === _routestop.stopid
      )
      if (routestop) {
        // there is an existing routestop, just update the record
        Object.assign(routestop, JSON.parse(JSON.stringify(_routestop)))
      } else {
        // no existing routestop found, insert new record
        _state.RouteStops.push(_routestop)
      }
    }
  },
  actions: {
    /**
     * @function RouteStopsClearAction
     * @description Vuex **action** which calls a Vuex mutation to clear the Vuex state store (the Firebase collection is not touched)
     * @author Patrick Nijsters
     * @description Vuex **action** that clears the entire Vuex state bu using a Vuex mutation. This function does not empty the firebase collection
     * @memberof module:moduleRouteStops
     * @param {VuexContext} _context
     */
    async RouteStopsClearAction(_context) {
      _context.commit('RouteStopsClearMutation')
    },

    /**
     * @function RouteStopsDeleteAllAction
     * @description Vue **action** that deletes all the entries from the Firebase collection and empties the Vuex state store as well.     *
     * @author Patrick Nijsters
     * @memberof module:moduleRouteStops
     * @param {VuexContext} _context
     */
    async RouteStopsDeleteAllAction(_context) {
      _context.state.RouteStops.forEach((routestop) => {
        db.collection(
          `users/${_context.rootState.moduleUser.CurrentUser.uid}/rallies/${_context.rootState.moduleUser.UserProfile.activerallyid}/routes/${_context.rootState.moduleUser.UserProfile.activerouteid}/stops`
        )
          .doc(routestop.stopid)
          .delete()
      })
      _context.commit('RouteStopsClearMutation')
    },

    /**
     * @function RouteStopsReadAllAction
     * @description Vuex **action** that uses the Firebase API to read every routestop into the Vuex state store using a Vuex mutation
     * @author Patrick Nijsters
     * @memberof module:moduleRouteStops
     * @async
     * @param {VuexContext} _context
     * @requires firebaseConfig.js
     * @returns {Void}
     */
    async RouteStopsReadAllAction(_context) {
      _context.commit('RouteStopsClearMutation')
      let result = await db
        .collection(
          `users/${_context.rootState.moduleUser.CurrentUser.uid}/rallies/${_context.rootState.moduleUser.UserProfile.activerallyid}/routes/${_context.rootState.moduleUser.UserProfile.activerouteid}/stops`
        )
        .get()
      for (let document in result.docs) {
        let savedocument = { ...result.docs[document].data() }
        savedocument.__proto__ = RouteStopsPrototype.prototype
        _context.commit('RouteStopsReadMutation', savedocument)
      }
    },

    /**
     * @function RouteStopsPushAction
     * @description Vuex **action** which only updates the Vuex state store without updating the corresponding Firebase collection document\
     * **USE WITH CARE: THIS IS ONLY USED TO TEMPORARILY STORE A NEW ROUTESTOP AS WE CALCULATE FUELDISTANCE!**
     * @author Patrick Nijsters
     * @memberof module:moduleRouteStops
     * @async
     * @param {VuexContext} _context
     * @param {RouteStopsPrototype} _routestop
     * @requires firebaseConfig.js
     * @returns {Void}
     */
    async RouteStopsPushAction(_context, _routestop) {
      _context.commit('RouteStopsCreateUpdateMutation', _routestop)
    },

    /**
     * @function RouteStopsCreateUpdateAction
     * @description Vuex **action** which
     * 1. calls the firestore API to create or overwrite an existing routestop in the specified Firestore collection
     * 2. calls a Vuex mutation that updates a routestop
     * @author Patrick Nijsters
     * @memberof module:moduleRouteStops
     * @async
     * @param {VuexContext} _context
     * @param {RouteStopsPrototype} _routestop
     * @requires firebaseConfig.js
     * @returns {Void}
     */
    async RouteStopsCreateUpdateAction(_context, _routestop) {
      db.collection(
        `users/${_context.rootState.moduleUser.CurrentUser.uid}/rallies/${_context.rootState.moduleUser.UserProfile.activerallyid}/routes/${_context.rootState.moduleUser.UserProfile.activerouteid}/stops`
      )
        .doc(_routestop.stopid)
        .set(JSON.parse(JSON.stringify(_routestop)))
      _context.commit('RouteStopsCreateUpdateMutation', _routestop)
    },

    /**
     * @function RouteStopsDeleteAction
     * @description Vuex **action** which deletes a specific routestop object from both the Firebase collection and the Vuex state store
     * @author Patrick Nijsters
     * @memberof module:moduleRouteStops
     * @async
     * @param {VuexContext} _context
     * @param {RouteStopsPrototype} _routestop
     * @requires firebaseConfig.js
     * @returns {Void}
     */
    async RouteStopsDeleteAction(_context, _routestop) {
      await db
        .collection(
          `users/${_context.rootState.moduleUser.CurrentUser.uid}/rallies/${_context.rootState.moduleUser.UserProfile.activerallyid}/routes/${_context.rootState.moduleUser.UserProfile.activerouteid}/stops`
        )
        .doc(_routestop.stopid)
        .delete()
      _context.commit('RouteStopsDeleteMutation', _routestop)
    }
  },
  getters: {
    /**
     * @function RouteStopsByIdGetter
     * @description Vuex **getter** thatr returns a single RouteStop object tht corresponds to the globally unique UUIDv4 that was provided as input
     * @author Patrick Nijsters
     * @memberof module:moduleRouteStops
     * @param {Object} _state Vuex state object
     * @param {UUIDv4} _routestopid
     * @returns {RouteStopsPrototype}
     */
    RouteStopsByIdGetter: (_state) => (_routestopid) => {
      const routestop = _state.RouteStops.find(
        (routestop) => routestop.stopid === _routestopid
      )
      if (!routestop) return new RouteStopsPrototype()
      routestop.__proto__ = RouteStopsPrototype.prototype
      return routestop
    },
    /**
     * @function RouteStopsCumulativeDistanceByName
     * @description Vuex **getter** that returns the cumulative distance traveled up to and included to this routestop
     * @author Patrick Nijsters
     * @memberof module:moduleRouteStops
     * @param {Object} _state Vuex state object
     * @param {Object} _getters Vuex getters object
     * @param {RouteStopsPrototype} _routestop
     * @returns {Number}
     */
    RouteStopsCumulativeDistanceByName: (_state, _getters) => (_routestop) => {
      const sortedRouteStops = _getters.RouteStopsSortedByOrder
      return sortedRouteStops
        .filter((element) => element.order <= _routestop.order)
        .reduce((sum, current) => {
          return sum + Number(current.map.distance)
        }, 0)
    },

    /**
     * @function RouteStopsFuelStopsByName
     * @description Vuex **getter** that calculates the incremental number of fuelstops needed to cover the distance between the previous routestop in the route and this routestop
     * @author Patrick Nijsters
     * @memberof module:moduleRouteStops
     * @param {Object} _state Vuex state object
     * @param {Object} _getters Vuex getters object
     * @param {Object} _rootState Vuex rootState object
     * @param {RouteStopsPrototype} _routestop
     * @returns {Number} The incremental number of fuelstops needed to get from the previous routestop to this routestop
     */
    RouteStopsFuelStopsByName:
      (_state, _getters, _rootState) => (_routestop) => {
        if (!_routestop.name) return 0
        if (_state.RouteStops.length === 0) return 0
        const FUELRANGE = Number(
          _rootState.modulePreferences.Preferences.routing.fuel_range
        )
        const traveledDistance =
          _getters.RouteStopsCumulativeDistanceByName(_routestop)

        const gasStopsCurrentBonus = Math.ceil(traveledDistance / FUELRANGE)
        const gasStopsPreviousBonus = Math.ceil(
          (traveledDistance - Number(_routestop.map.distance)) / FUELRANGE
        )
        return (
          gasStopsCurrentBonus -
          gasStopsPreviousBonus -
          (_routestop.order === 2) // we start with a full tank at the start
        )
      },

    /**
     * @function RouteStopsRestPointsByName
     * @description Vuex **getter** returning the number of earned points for a specific routestop location
     * @author Patrick Nijsters
     * @memberof module:moduleRouteStops
     * @param {Object} _state Vuex state object
     * @param {Object} _getters Vuex getters object
     * @param {Object} _rootState Vuex rootState object
     * @param {Object} _rootGetters Vuex rootGetters object
     * @returns {Number}
     */
    RouteStopsRestPointsByName:
      (_state, _getters, _rootState, _rootGetters) => (_searchLocation) => {
        if (_state.RouteStops.length === 0) return 0
        let result = _state.RouteStops.find(
          (routeStop) => routeStop.name === _searchLocation
        )
        if (result === undefined || result.restbonusandtime === null) {
          return 0
        }
        let totalpoints = 0
        let work = result.restbonusandtime.split(',')
        for (let index in work) {
          if (work[index].split('-')[0] === 'NOPOINTS') return 0
          let restBonus = _rootGetters[
            'moduleBonusLocations/BonusLocationsByNameGetter'
          ](work[index].split('-')[0])
          let restStopPoints = 0
          let pointsPerMinute = Number(
            restBonus.points / restBonus.restmaxduration
          )
          let planDuration = work[index].split('-')[1]
          restStopPoints = Math.round(
            Number(pointsPerMinute) * Number(planDuration)
          )
          if (Number(planDuration) < Number(restBonus.restminduration)) {
            restStopPoints = 0
          }
          if (Number(planDuration) > Number(restBonus.restmaxduration)) {
            restStopPoints = Math.round(
              Number(restBonus.restmaxduration) * Number(pointsPerMinute)
            )
          }
          totalpoints = totalpoints + restStopPoints
        }
        return totalpoints
      },

    /**
     * @function RouteStopsTotalStoppedTime
     * @description Vuex **getter** that turns the total stopped time, in minutes, for the entire route
     * @author Patrick Nijsters
     * @memberof module:moduleRouteStops
     * @param {Object} _state Vuex state object
     * @param {Object} _getters Vuex getters object
     * @param {Object} _rootState Vuex rootState object
     * @returns {Number}
     */
    RouteStopsTotalStoppedTime: (_state, _getters, _rootState) => {
      if (_state.RouteStops.length === 0) return 0
      const sortedRouteStops = _getters.RouteStopsSortedByOrder
      const FUELSTOPDURATION =
        _rootState.modulePreferences.Preferences.routing.fuel_stopduration
      return (
        sortedRouteStops.reduce((sum, current) => {
          return (sum =
            sum +
            Number(_getters.RouteStopsFuelStopsByName(current)) *
              FUELSTOPDURATION +
            Number(_getters.RouteStopsRestDurationByName(current)) +
            (current.order >= 2
              ? Number(sortedRouteStops[Number(current.order) - 2].dwelltime)
              : 0))
        }, 0) / 60
      )
    },

    /**
     * @function RouteStopsTotalRestTime
     * @description Vuex **getter** that returns the total amount spent resting, in minutes, for the entire route
     * @author Patrick Nijsters
     * @memberof module:moduleRouteStops
     * @param {Object} _state Vuex state object
     * @param {Object} _getters Vuex getters object
     * @returns {Number}
     */
    RouteStopsTotalRestTime: (_state, _getters) => {
      if (_state.RouteStops.length === 0) return 0
      const sortedRouteStops = _getters.RouteStopsSortedByOrder
      return (
        sortedRouteStops.reduce((sum, current) => {
          return sum + Number(_getters.RouteStopsRestDurationByName(current))
        }, 0) / 60
      )
    },

    /**
     * @function RouteStopsRestDurationByName
     * @description Vuex **getter** that calculates the time spent resting between the departure of the previous stop and the departure of this stop
     * @author Patrick Nijsters
     * @memberof module:moduleRouteStops
     * @param {RouteStopsPrototype} _routestop
     * @returns {Number}
     */
    RouteStopsRestDurationByName: () => (_routestop) => {
      if (!_routestop.name) return 0
      if (_routestop.restbonusandtime === null) return 0
      return _routestop.restbonusandtime.split(',').reduce((sum, current) => {
        return sum + Number(current.split('-')[1])
      }, 0)
    },

    /**
     * @function RouteStopsTotalPoints
     * @description Vuex **mutation** that returns the total number of riding bonus points for the route
     * @author Patrick Nijsters
     * @memberof module:moduleRouteStops
     * @param {Object} _state Vuex state object
     * @param {Object} _getters Vuex getters object
     * @returns {Number}
     */
    RouteStopsTotalPoints: (_state, _getters) => {
      if (_state.RouteStops.length === 0) return 0
      return _state.RouteStops.reduce((sum, current) => {
        return (
          sum +
          Number(current.points) +
          Number(_getters.RouteStopsRestPointsByName(current.name))
        )
      }, 0)
    },

    /**
     * @function RouteStopsTotalDistance
     * @description Vuex **getter** that returns the total calculated distance for the route
     * @author Patrick Nijsters
     * @memberof module:moduleRouteStops
     * @param {Object} _state Vuex state object
     * @returns {Number}
     */
    RouteStopsTotalDistance: (_state) => {
      if (_state.RouteStops.length === 0) return 0
      return _state.RouteStops.reduce((sum, current) => {
        return sum + Number(current.map.distance)
      }, 0).toFixed(2)
    },

    /**
     * @function RouteStopsTotalTime
     * @description Vuex **getter** that returns the total time spent traveling, in minutes, for the route
     * @author Patrick Nijsters
     * @memberof module:moduleRouteStops
     * @param {Object} _state Vuex state object
     * @returns {Number}
     */
    RouteStopsTotalTime: (_state) => {
      if (_state.RouteStops.length === 0) return 0
      return _state.RouteStops.reduce((sum, current) => {
        return sum + Number(current.map.traveltime)
      }, 0).toFixed(2)
    },

    /**
     * @function RouteStopsTotalDwellTime
     * @description Vuex **getter** that returns the total dwell time, in minutes, for the route
     * @author Patrick Nijsters
     * @memberof module:moduleRouteStops
     * @param {Object} _state Vuex state object
     * @returns {Number}
     */
    RouteStopsTotalDwellTime: (_state) => {
      if (_state.RouteStops.length === 0) return 0
      return _state.RouteStops.reduce((sum, current) => {
        return sum + Number(current.map.dwelltime)
      }, 0).toFixed(2)
    },

    /**
     * @function RouteStopsTotalStops
     * @description Vuex **getter** that returns the total number of stops in the route (excluding the START and END checkpoints)
     * @author Patrick Nijsters
     * @memberof module:moduleRouteStops
     * @param {Object} _state Vuex state object
     * @returns {Number}
     */
    RouteStopsTotalStops: (_state) => {
      if (_state.RouteStops.length === 0) return 0
      return Number(_state.RouteStops.length) - 2
    },

    /**
     * @function RouteStopsTotalFuelStops
     * @description Vuex **getter** that returns the total number of fuelstops for the entire route
     * @author Patrick Nijsters
     * @memberof module:moduleRouteStops
     * @param {Object} _state Vuex state object
     * @param {Object} _getters Vuex getters object
     * @param {Object} _rootState Vuex rootState object
     * @returns {Number}
     */
    RouteStopsTotalFuelStops: (_state, _getters, _rootState) => {
      if (_state.RouteStops.length === 0) return 0
      const FUELRANGE =
        _rootState.modulePreferences.Preferences.routing.fuel_range
      let fuelstops = Math.ceil(
        _getters.RouteStopsTotalDistance / FUELRANGE - 1
      )
      return fuelstops > 0 ? fuelstops : 0
    },

    /**
     * @function RouteStopsTotalRestStops
     * @description Vuex **getter** that returns the total number of planned rest stops for the route
     * @author Patrick Nijsters
     * @memberof module:moduleRouteStops
     * @param {Object} _state Vuex state object
     * @returns {Number}
     */
    RouteStopsTotalRestStops: (_state) => {
      if (_state.RouteStops.length === 0) return 0
      return _state.RouteStops.reduce((sum, current) => {
        if (current.restbonusandtime !== null)
          return sum + Number(current.restbonusandtime.split(',').length)
        return sum
      }, 0)
    },

    /**
     * @function RouteStopsSortedByOrder
     * @description Vuex **getter** that returns the route stops sorted by the order field
     * @author Patrick Nijsters
     * @memberof module:moduleRouteStops
     * @param {Object} _state Vuex state object
     * @returns {Number}
     */
    RouteStopsSortedByOrder: (_state) => {
      if (_state.RouteStops.length === 0) return []
      let sortedRouteStops = JSON.parse(JSON.stringify(_state.RouteStops))
      return assignPrototype(
        sortedRouteStops.sort((a, b) => (a.order > b.order ? 1 : -1))
      )
    },

    /**
     * @funcion RouteStopsSortedByOrderIndexByName
     * @description Vuex **getter** that returns the index of a routestop (by name) in the Vuex state store
     * @author Patrick Nijsters
     * @memberof module:moduleRouteStops
     * @param {Object} _state Vuex state object
     * @param {Object} _getters Vuex getters object
     * @returns {RouteStopsPrototype}
     */
    RouteStopsSortedByOrderIndexByName:
      (_state, _getters) => (_searchRouteStop) => {
        const sortedRouteStops = _getters.RouteStopsSortedByOrder
        return sortedRouteStops.indexOf(
          sortedRouteStops.find(
            (routestop) => routestop.name === _searchRouteStop
          )
        )
      },

    /**
     * @function RouteStopsByName
     * @description Vuex **getter** that returns a single RouteStops object based on the name of the provided routestop
     * @author Patrick Nijsters
     * @memberof module:moduleRouteStops
     * @param {Object} _state Vuex state object
     * @returns {RouteStopsPrototype}
     */
    RouteStopsByName: (_state) => (_searchLocation) => {
      if (_state.RouteStops.length === 0) return {}
      let result = _state.RouteStops.find(
        (routeStop) => routeStop.name === _searchLocation
      )
      if (result === undefined) {
        result = {}
      }
      result.__proto__ = RouteStopsPrototype.prototype
      return result
    }
  }
}

/**
 * @description Vuex module for Route Stops
 */
export default moduleRouteStops
