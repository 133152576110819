/**
 * @module moduleRoutes
 */

import { db } from '@/firebaseConfig.js'
import { RoutesPrototype } from '@/components/prototypes/routes.js'

/**
 * @function assignPrototype
 * @description This function takes an array of route objects and ensures the array is returned with all objects correctly set to the RoutesPrototype prototype. If the correct prototype is already set on the objects in the array, the original array will be returned.
 * @author Patrick Nijsters
 * @memberof module:moduleRoutes
 * @param {Array.<Object>} _array An array of route object that might or might not have the correct prototype set.
 * @returns {Array.<RoutesPrototype>}
 */
function assignPrototype(_array) {
  _array.forEach((element) => (element.__proto__ = RoutesPrototype.prototype))
  return _array
}

const moduleRoutes = {
  namespaced: true,
  state: {
    Routes: []
  },
  mutations: {
    /**
     * @function RoutesReadMutation
     * @author Patrick Nijsters
     * @description Vuex **mutation** that stores the Routes object passed to it in the Vuex state store
     * @memberof module:moduleRoutes
     * @param {VuexContext} _state Vuex state object
     * @param {RoutesPrototype} _route Routes object
     * @returns {Void}
     */
    RoutesReadMutation: (_state, _route) => {
      _state.Routes.push(_route)
    },

    /**
     * @function RoutesClearMutation
     * @author Patrick Nijsters
     * @description Vuex **mutation** that deletes all Routes objects from the Vuex state store
     * @memberof module:moduleRoutes
     * @param {VuexContext} _state Vuex state object
     * @returns {Void}
     */
    RoutesClearMutation: (_state) => {
      _state.Routes = []
    },

    /**
     * @function RoutesDeleteMutation
     * @author Patrick Nijsters
     * @description Vuex **mutation** that deletes a single route from the state store based on the Routes object passed to it
     * @memberof module:moduleRoutes
     * @param {Object} _state Vuex state object
     * @param {RoutesPrototype} _route Route object
     * @returns {Void}
     */
    RoutesDeleteMutation: (_state, _routeid) => {
      const route = _state.Routes.find((route) => route.routeid === _routeid)
      const index = _state.Routes.indexOf(route)
      _state.Routes.splice(index, 1)
    },

    /**
     * @function RoutesCreateUpdateMutation
     * @author Patrick Nijsters
     * @description Vuex **mutation** that either updates a single route's Routes object if it already exists or creates a new entry in the Vuex state store
     * @memberof module:moduleRoutes
     * @param {VuexContext} _state Vuex state object
     * @param {RoutesPrototype} _route Routes object
     * @returns {Void}
     */
    RoutesCreateUpdateMutation: (_state, _route) => {
      const result = _state.Routes.find(
        (route) => route.routeid === _route.routeid
      )
      if (result) {
        Object.assign(result, JSON.parse(JSON.stringify(_route)))
      } else {
        _state.Routes.push(_route)
      }
    }
  },
  actions: {
    /**
     * @function RoutesDeleteAction
     * @author Patrick Nijsters
     * @description Vuex **action** which
     * 1. Removes a specific route from the Firestore using the Firestore API
     * 2. Removes a specific route from the Vuex state store using a Vuex mutation
     * @memberof module:moduleRoutes
     * @param {VuexContext} _context Vuex context {state, rootState, commit, dispatch, getters, rootGetters}
     * @param {UUIDv4} _route Globally unique UUIDv4 route object ID
     * @returns {Void}
     * @see module:moduleRoutes.RoutesDeleteMutation
     */
    async RoutesDeleteAction(_context, _routeid) {
      await db
        .collection(
          `users/${_context.rootState.moduleUser.CurrentUser.uid}/rallies/${_context.rootState.moduleUser.UserProfile.activerallyid}/routes`
        )
        .doc(_routeid)
        .delete()
      _context.commit('RoutesDeleteMutation', _routeid)
    },

    /**
     * @function RoutesDeleteAllAction
     * @description Vue **action** that deletes all the entries from the Firebase collection and empties the Vuex state store as well.     *
     * @author Patrick Nijsters
     * @memberof module:moduleRoutes
     * @param {VuexContext} _context
     */
    async RoutesDeleteAllAction(_context) {
      await _context.dispatch('RoutesReadAllAction')
      for (let index in _context.state.Routes) {
        //find all the routestops for this route plan
        let result = await db
          .collection(
            `users/${_context.rootState.moduleUser.CurrentUser.uid}/rallies/${_context.rootState.moduleUser.UserProfile.activerallyid}/routes/${_context.state.Routes[index].routeid}/stops`
          )
          .get()
        //and delete them one by one
        for (let document in result.docs) {
          await db
            .collection(
              `users/${_context.rootState.moduleUser.CurrentUser.uid}/rallies/${_context.rootState.moduleUser.UserProfile.activerallyid}/routes/${_context.state.Routes[index].routeid}/stops`
            )
            .doc(result.docs[document].id)
            .delete()
        }
        //and now lets delete the routeplan itself
        db.collection(
          `users/${_context.rootState.moduleUser.CurrentUser.uid}/rallies/${_context.rootState.moduleUser.UserProfile.activerallyid}/routes`
        )
          .doc(_context.state.Routes[index].routeid)
          .delete()
      }
      _context.commit('RoutesClearMutation')
    },

    /**
     * @function RoutesReadAllAction
     * @author Patrick Nijsters
     * @description Vuex **action** which
     * 1. Clears all routes from the Vuex state store
     * 2. Uses the Firestore API to read all routes from the Firestore
     * 3. For each route found in the Firestore, calls a Vuex mutation to add this route to the Vuex state store
     * @memberof module:moduleRoutes
     * @param {VuexContext} _context Vuex context {state, rootState, commit, dispatch, getters, rootGetters}
     * @returns {Void}
     * @see module:moduleRoutes.RoutesReadMutation
     */
    async RoutesReadAllAction(_context) {
      _context.commit('RoutesClearMutation')
      await db
        .collection(
          `users/${_context.rootState.moduleUser.CurrentUser.uid}/rallies/${_context.rootState.moduleUser.UserProfile.activerallyid}/routes`
        )
        .get()
        .then(function (result) {
          for (let document in result.docs) {
            _context.commit('RoutesReadMutation', result.docs[document].data())
          }
        })
    },

    /**
     * @function RoutesClearAction
     * @author Patrick Nijsters
     * @description Vuex **action** that deletes all Routes objects from the Vuex state store
     * @memberof module:moduleRoutes
     * @param {VuexContext} _context Vuex context {state, rootState, commit, dispatch, getters, rootGetters}
     * @returns {Void}
     * @see module:moduleRoutes.RoutesClearMutation
     */
    RoutesClearAction: (_context) => {
      _context.commit('RoutesClearMutation')
    },

    /**
     * @function RoutesCreateUpdateAction
     * @author Patrick Nijsters
     * @description Vuex **action** which
     * 1. Updates or inserts a route in the Firestore using the Firestore API
     * 2. Updates or inserts the same route in the Vuex state store using a Vuex mutation
     * @memberof module:moduleRoutes
     * @param {VuexContext} _context Vuex context {state, rootState, commit, dispatch, getters, rootGetters}
     * @param {Routes} _route
     * @returns {Void}
     * @see module:moduleRoutes.RoutesCreateUpdateMutation
     */
    async RoutesCreateUpdateAction(_context, _route) {
      await db
        .collection(
          `users/${_context.rootState.moduleUser.CurrentUser.uid}/rallies/${_context.rootState.moduleUser.UserProfile.activerallyid}/routes`
        )
        .doc(_route.routeid)
        .set(JSON.parse(JSON.stringify(_route)))
      _context.commit('RoutesCreateUpdateMutation', _route)
    }
  },
  getters: {
    /**
     * @function RoutesGetRouteByIdGetter
     * @description Vuex **getter** thatr returns a single Route object tht corresponds to the globally unique UUIDv4 that was provided as input
     * @author Patrick Nijsters
     * @memberof module:moduleRoutes
     * @param {Object} _state Vuex state object
     * @param {UUIDv4} _routestopid
     * @returns {RoutesPrototype}
     */
    RoutesGetRouteByIdGetter: (_state) => (_routeid) => {
      const activeroute = _state.Routes.find(
        (route) => route.routeid === _routeid
      )
      if (!activeroute) return new RoutesPrototype()
      activeroute.__proto__ = RoutesPrototype.prototype
      return activeroute
    },

    /**
     * @function RoutesGetMostRecentRouteGetter
     * @description Vuex **getter** that returns a single Route object that corresponds to the most recently worked on route (based on the field 'modified')
     * @author Patrick Nijsters
     * @memberof module:moduleRoutes
     * @param {Object} _state Vuex state object
     * @returns {RoutesPrototype}
     */
    RoutesGetMostRecentRouteGetter: (_state) => () => {
      if (_state.Routes.length === 0) return null
      const mostrecentroute = _state.Routes.reduce((a, b) => {
        return new Date(a.modified) > new Date(b.modified) ? a : b
      })
      mostrecentroute.__proto__ = RoutesPrototype.prototype
      return mostrecentroute
    },

    /**
     * @function RoutesSortedByTimeGetter
     * @description Vuex **getter** that returns a sorted array of Routes objects sorted by the 'modified' field
     * @author Patrick Nijsters
     * @memberof module:moduleRoutes
     * @param {Object} _state Vuex state object
     * @returns {Array.<RoutesPrototype>}
     */
    RoutesSortedByTimeGetter: (_state) => {
      if (_state.Routes.length === 0) return []
      let sortedRoutes = JSON.parse(JSON.stringify(_state.Routes))
      return assignPrototype(
        sortedRoutes.sort((a, b) =>
          new Date(a.modified) > new Date(b.modified) ? 1 : -1
        )
      )
    }
  }
}

/**
 * @description Vuex module for Routes
 */
export default moduleRoutes
