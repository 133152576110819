import * as axios from 'axios'
import { DateTime } from 'luxon'
import store from '@/store'
import { getSunrise, getSunset } from 'sunrise-sunset-js'
import LatLon from 'geodesy/latlon-ellipsoidal-vincenty.js'
import { v4 as uuidv4 } from 'uuid'
import {
  MATERIALICONSYMBOLS,
  GARMINSYMBOLS
} from '@/components/prototypes/constants.js'

/**
 * @class
 * @author Patrick Nijsters
 * @property {String} name The short name of the bonus as listed in the rally book
 * @property {UUIDv4} bonusid Globally unique identifier for this bonus location
 * @property {Number} latitude The latitude of the bonus location in [decimal degrees format](https://en.wikipedia.org/wiki/Decimal_degrees)
 * @property {Number} longitude The longitude of the bonus location in [decimal degrees format](https://en.wikipedia.org/wiki/Decimal_degrees)
 * @property {String} description Description of the bonus location
 * @property {String} comment Comments for the bonus location
 * @property {Number} points The numerical point value of the bonus location as specified in the rally book
 * @property {Boolean} unpaved=false Indicates if reaching the bonus location includes unpaved road access
 * @property {String} longname The calculated long name according to the configured preferences of the user. Ttpically includes indicators of bonus size (S/M/L), bonus category (Daylight only, Timed) amongst other indicators
 * @property {String} timerestrictions A specific string format indicating time restrictions for this bonus as listed in the rally book.\
 * The string is a concatenation of one or more '[day part]@[time part]' blocks separated by the pipe ('|') symbol:\
 * \
 *  _[day part]@[time part]|[day part]@[time part]_ where:
 * * **[day part]** = [] or [mon] or [mon-fri] or [mon,tue,fri] or [mon-wed, fri-sat]
 * * **[time part]** = [9A] or [7A-7P] or [7A-8A,4P-5P] or [8A,8P]
 * * **[time part]** = [9:00] or [7:00-19:00] or [7:00-8:00,16:00-17:00] or [8:00-20:00]
 * * **SR** = sunrise
 * * **SS** = sunset
 * @property {String} page The page number in the rally book this bonus is listed on. This is not a Number field because the page number can be alphanumerical.
 * @property {D|A|T|S|N|DC|AC|TC} category The category of the bonus location.
 * * **D** for Daylight Only bonus location
 * * **A** for Any time bonus location
 * * **T** for Timerestricted bonus location
 * * **S** for START or END of rally bonus location
 * * **N** for Non-mappable bonus (rest, call-in, tracking, other)
 * * **DC** for Daylight only Combo bonus (one or more bonuses in this combo are Daylight only)
 * * **AC** for Any time Combo bonus (all bonuses in this combo are Any time)
 * * **TC** for Timerestricted Combo bonus (one or more bonuses in this combo are Timerestricted)
 * @property {String} sunset Time of sunset for this bonus location on the date of the start of the rally in hh:mm format
 * @property {String} sunrise Time of sunrise for this bonus location on the date of the start of the rally in hh:mm format
 * @property {String} timezoneid The timezone of the location in its most descriptive format like "Americas/Chicago"
 * @property {String} proximity Comma separated string (no white space) of all bonus locations within close proximity where proximity is defined in user preferences
 * @property {small|medium|large|extralarge} value The 'value' of the bonus location based on the point categories as configured by the user
 * @property {String} symbol Comma separated string (with white space) of two arguments. First argument is the name of the symbol, second argument is the color of the symbol. The name and color of symbol are user configurable in the preferences section. This field should only be filled by using the correct function, do not write directly to this property
 * @property {Number} entryorder Order in which the bonus location was entered into the rally leg
 * @property {Number} combototalpoints (**combo only**) the point value for the combo as defined in the rally book
 * @property {String} combostops (**combo only**) all the bonus locations that make up this combo
 * @property {Number} bestroutelength (**combo only**) the distance, in miles, of the shortest possible route connection all combo bonus locations
 * @property {Number} restminduration (**rest bonus only**) the minimum amount of time to rest, in minutes, for points to be awarded for this rest bonus
 * @property {Number} restmaxduration (**rest bonus only**) the maximum amount of time, in minutes, eligible for points for this rest bonus
 * @property {String} timezoneShort The timezone of the location in the shortest form like "CDT"
 * @property {String} timezoneLong The timezone of the location in the longest form like "Americas/Chicago (CDT)"
 * @example {
 * name:"GDG"
 * latitude:44.483567000,
 * longitude:-92.261683000,
 * description:"This is some descriptive text",
 * comment:"24 hours",
 * points:238,
 * unpaved:false,
 * longname:"GDG-A-238-P18",
 * timerestrictions:"Mon-Fri@9A-5P",
 * page:"18",
 * category:"A",
 * sunset:"20:51",
 * sunrise:"05:24",
 * timezoneid:"America/Chicago",
 * proximity:"AIR,GDG,BOB",
 * value:"medium",
 * symbol:"triangle, green",
 * entryorder:71,
 * }
 */
export function BonusLocationsPrototype(
  name = null,
  bonusid = null,
  latitude = null,
  longitude = null,
  description = null,
  comment = null,
  points = null,
  unpaved = null,
  longname = null,
  timerestrictions = null,
  page = null,
  category = null,
  sunset = null,
  sunrise = null,
  timezoneid = null,
  proximity = null,
  value = null,
  symbol = null,
  entryorder = null,
  combototalpoints = null,
  combostops = null,
  bestroutelength = null,
  restminduration = null,
  restmaxduration = null,
  timezoneShort = null,
  timezoneLong = null
) {
  this.name = name
  this.bonusid = bonusid
  this.latitude = latitude
  this.longitude = longitude
  this.description = description
  this.comment = comment
  this.points = points
  this.unpaved = unpaved
  this.longname = longname
  this.timerestrictions = timerestrictions
  this.page = page
  this.category = category
  this.sunset = sunset
  this.sunrise = sunrise
  this.timezoneid = timezoneid
  this.proximity = proximity
  this.value = value
  this.symbol = symbol
  this.entryorder = entryorder
  this.combototalpoints = combototalpoints
  this.combostops = combostops
  this.bestroutelength = bestroutelength
  this.restminduration = restminduration
  this.restmaxduration = restmaxduration
  this.timezoneShort = timezoneShort
  this.timezoneLong = timezoneLong
}
/**
 * @description Sets the globally unique UUIDv4 ID of the bonus location
 * @author Patrick Nijsters
 * @requires UUIDv4
 * @returns {Void}
 */
BonusLocationsPrototype.prototype.setID = function () {
  this.bonusid = uuidv4()
}

BonusLocationsPrototype.prototype.setStartcheckpoint = async function () {
  this.bonusid = uuidv4()
  this.name = 'START'
  this.points = 0
  this.description = 'Start of rally'
  this.comment = 'Start of rally'
  this.longname = 'START'
  this.category = 'S'
  this.symbol = 'flag, green'
  await this.setTimezone()
  // this.setSunrise()
  // this.setSunset()
}

BonusLocationsPrototype.prototype.setEndcheckpoint = async function () {
  this.bonusid = uuidv4()
  this.name = 'END'
  this.points = 0
  this.description = 'End of rally'
  this.comment = 'End of rally'
  this.longname = 'END'
  this.category = 'S'
  this.symbol = 'flag, red'
  await this.setTimezone()
  // this.setSunrise()
  // this.setSunset()
}

/**
 * @description Based on the latitude and longitude calculate the sunrise and store it in the corresponding class parameter
 * @author Patrick Nijsters
 * @requires Vuex
 * @requires luxon
 * @requires getSunrise
 * @returns {Boolean}
 */
BonusLocationsPrototype.prototype.setSunrise = function (_alternativedatetime) {
  if (this.timezoneid === null) return false
  if (_alternativedatetime) {
    this.sunrise = DateTime.fromISO(
      `${JSON.stringify(
        getSunrise(
          this.latitude,
          this.longitude,
          new Date(Number(DateTime.fromISO(_alternativedatetime).toFormat('x')))
        )
      ).substr(1, 23)}`,
      {
        zone: 'UTC'
      }
    )
      .setZone(this.timezoneid)
      .toFormat('HH:mm')
  } else {
    this.sunrise = DateTime.fromISO(
      `${JSON.stringify(
        getSunrise(
          this.latitude,
          this.longitude,
          new Date(
            Number(
              DateTime.fromISO(
                store.state.moduleRallies.Rallies.filter(
                  (rally) =>
                    rally.rallyid ===
                    store.state.moduleUser.UserProfile.activerallyid
                )[0].start.datetime
              ).toFormat('x')
            )
          )
        )
      ).substr(1, 23)}`,
      {
        zone: 'UTC'
      }
    )
      .setZone(this.timezoneid)
      .toFormat('HH:mm')
  }

  return true
}

/**
 * @description Based on the latitude and longitude calculate the sunset and store it in the corresponding class parameter
 * @author Patrick Nijsters
 * @requires luxon
 * @requires Vuex
 * @requires getSunrise
 * @returns {Boolean}
 */
BonusLocationsPrototype.prototype.setSunset = function (_alternativedatetime) {
  if (this.timezoneid === null) return false //we need to know the timezone the location is in to be able to calculate the correct sunset time
  if (_alternativedatetime) {
    this.sunset = DateTime.fromISO(
      `${JSON.stringify(
        getSunset(
          this.latitude,
          this.longitude,
          new Date(Number(DateTime.fromISO(_alternativedatetime).toFormat('x')))
        )
      ).substr(1, 23)}`,
      {
        zone: 'UTC'
      }
    )
      .setZone(this.timezoneid)
      .toFormat('HH:mm')
  } else {
    this.sunset = DateTime.fromISO(
      `${JSON.stringify(
        getSunset(
          this.latitude,
          this.longitude,
          new Date(
            Number(
              DateTime.fromISO(
                store.state.moduleRallies.Rallies.filter(
                  (rally) =>
                    rally.rallyid ===
                    store.state.moduleUser.UserProfile.activerallyid
                )[0].start.datetime
              ).toFormat('x')
            )
          )
        )
      ).substr(1, 23)}`,
      {
        zone: 'UTC'
      }
    )
      .setZone(this.timezoneid)
      .toFormat('HH:mm')
  }
  return true
}

/**
 * @description Calculates the timezone based on latitude and longitude using a Google API query and stores it in the corresponding class parameter
 * @author Patrick Nijsters
 * @requires Vuex
 * @requires luxon
 * @requires axios
 * @returns {Boolean}
 */
BonusLocationsPrototype.prototype.setTimezone = async function () {
  if (!this.latitude || !this.longitude) return false //we need latitude and longitude to query Google for the timezone
  if (this.timezoneid) return true //timezone is already set
  let date = DateTime.now().toFormat('x')

  const GOOGLE_URL =
    'https://maps.googleapis.com/maps/api/timezone/json?location='
  const GOOGLE_API_KEY =
    store.state.modulePreferences.Preferences.secrets.GOOGLE_API_KEY

  let axiosQuery = `${GOOGLE_URL}${this.latitude},${
    this.longitude
  }&timestamp=${Math.floor(date / 1000)}&key=${GOOGLE_API_KEY}`

  let axiosResult = await axios.get(axiosQuery)
  this.timezoneid = axiosResult.data.timeZoneId
  this.timezoneLong = `${this.timezoneid} (${DateTime.now()
    .setZone(this.timezoneid)
    .toFormat('ZZZZ')})`
  this.timezoneShort = `${DateTime.now()
    .setZone(this.timezoneid)
    .toFormat('ZZZZ')}`
  return true
}

/**
 * @author Patrick Nijsters
 * @requires Vuex
 * @requires luxon
 * @returns {Boolean}
 */
BonusLocationsPrototype.prototype.setLongname = function () {
  if (!this.name) return false
  this.longname = ''
  let formatPreferences = store.state.modulePreferences.Preferences.format
  if (this.category === 'N') {
    if (this.points) {
      this.longname = `${this.name}-${this.points}`
    } else {
      this.longname = this.name
    }
  } else {
    for (let index in formatPreferences.field_order) {
      if (formatPreferences.field_order[index].category === 'separator') {
        this.longname =
          this.longname + formatPreferences.field_order[index].name
      } else {
        switch (formatPreferences.field_order[index].name) {
          case 'Bonus ID':
            if (this.name) {
              this.longname = this.longname + this.name
            }
            break
          case 'Category':
            if (this.category) {
              this.longname = this.longname + this.category
            }
            break
          case 'Points':
            if (this.points) {
              this.longname = this.longname + this.points
            }
            break
          case 'Unpaved':
            if (this.unpaved) {
              switch (formatPreferences.fields.unpaved) {
                case '!':
                  this.longname = this.longname + '!'
                  break
                case '(!)':
                  this.longname = this.longname + '(!)'
                  break
                case 'UNPVD':
                  this.longname = this.longname + 'UNPVD'
                  break
              }
            }
            break
          case 'Time restrictions':
            if (this.timerestrictions) {
              if (this.category === 'T') {
                switch (formatPreferences.fields.t_time) {
                  case '7A-5P':
                    this.longname = this.longname + this.timerestrictions
                    break
                  case '(7A-5P)':
                    this.longname = `${this.longname}(${this.timerestrictions})`
                    break
                }
              }
              if (this.category === 'D') {
                switch (formatPreferences.fields.d_time) {
                  case '6:24-19:35':
                    this.longname = `${this.longname}${this.sunrise}-${this.sunset}`
                    break
                  case '(6:24-19:35)':
                    this.longname = `${this.longname}(${this.sunrise}-${this.sunset})`
                    break
                }
              }
            }
            break
          case 'Rally book page':
            if (this.page) {
              switch (formatPreferences.fields.page) {
                case '79':
                  this.longname = this.longname + this.page
                  break
                case 'P79':
                  this.longname = `${this.longname}P${this.page}`
                  break
              }
            }
            break
          case 'Timezone':
            if (this.timezoneid) {
              this.longname =
                this.longname +
                DateTime.now().setZone(this.timezoneid).toFormat('ZZZZ')
            }
            break
        }
      }
    }
  }
  return true
}

/**
 * @author Patrick Nijsters
 * @requires Vuex
 * @returns {Boolean}
 */
BonusLocationsPrototype.prototype.setEntryorder = function () {
  if (this.entryorder !== null) return false //entry order has already been set
  let maximum = 0
  for (let index in store.state.moduleBonusLocations.BonusLocations) {
    if (
      Number(
        store.state.moduleBonusLocations.BonusLocations[index].entryorder
      ) > maximum
    ) {
      maximum = Number(
        store.state.moduleBonusLocations.BonusLocations[index].entryorder
      )
    }
  }
  this.entryorder = maximum + 1
  return true
}

/**
 * @author Patrick Nijsters
 * @requires Vuex
 * @returns {Boolean}
 */
BonusLocationsPrototype.prototype.setValue = function () {
  if (!this.points) return false
  if (this.category === 'S') return false
  let tshirtArray = store.state.moduleRallies.Rallies.find(
    (rally) =>
      rally.rallyid === store.state.moduleUser.UserProfile.activerallyid
  ).tshirt
  let colorArray = {}
  tshirtArray.small.symbol =
    store.state.modulePreferences.Preferences.symbol.tshirt.small.symbol
  tshirtArray.medium.symbol =
    store.state.modulePreferences.Preferences.symbol.tshirt.medium.symbol
  tshirtArray.large.symbol =
    store.state.modulePreferences.Preferences.symbol.tshirt.large.symbol
  tshirtArray.extralarge.symbol =
    store.state.modulePreferences.Preferences.symbol.tshirt.extralarge.symbol
  colorArray.A = store.state.modulePreferences.Preferences.symbol.color.A
  colorArray.D = store.state.modulePreferences.Preferences.symbol.color.D
  colorArray.T = store.state.modulePreferences.Preferences.symbol.color.T

  let value = ''
  let symbol = ''
  let points = 0

  if (
    (this.category === 'AC') |
    (this.category === 'DC') |
    (this.category === 'TC')
  ) {
    points = this.combototalpoints
  } else {
    points = this.points
  }

  if (points <= tshirtArray.small.maximum) {
    value = 'small'
    symbol = tshirtArray.small.symbol
  } else if (
    points > tshirtArray.small.maximum &&
    points <= tshirtArray.medium.maximum
  ) {
    value = 'medium'
    symbol = tshirtArray.medium.symbol
  } else if (
    points > tshirtArray.medium.maximum &&
    points <= tshirtArray.large.maximum
  ) {
    value = 'large'
    symbol = tshirtArray.large.symbol
  } else if (points > tshirtArray.large.maximum) {
    value = 'extra large'
    symbol = tshirtArray.extralarge.symbol
  }

  if (value !== undefined) {
    switch (this.category) {
      case 'A':
        symbol = `${symbol}, ${colorArray.A}`
        break
      case 'D':
        symbol = `${symbol}, ${colorArray.D}`
        break
      case 'T':
        symbol = `${symbol}, ${colorArray.T}`
    }
  }
  if (value === '') {
    symbol = ''
  }
  this.value = value
  // Skip setting the symbol for non-mappable bonuses since that is already done in the dialAddNonRidingBonus dialog
  if (this.category !== 'N') this.symbol = symbol
  return true
}

/**
 * @author Patrick Nijsters
 * @requires Vuex
 * @requires LatLon
 * @returns {Boolean}
 */
BonusLocationsPrototype.prototype.setProximity = function () {
  let proxLocations = ''
  for (let bonuslocation in store.getters[
    'moduleBonusLocations/BonusLocationsRidingOnlyGetter'
  ]) {
    let distance =
      new LatLon(this.latitude, this.longitude).distanceTo(
        new LatLon(
          store.getters['moduleBonusLocations/BonusLocationsRidingOnlyGetter'][
            bonuslocation
          ].latitude,
          store.getters['moduleBonusLocations/BonusLocationsRidingOnlyGetter'][
            bonuslocation
          ].longitude
        )
      ) / 1609
    if (
      (distance <=
        store.state.modulePreferences.Preferences.routing.cluster_proximity) &
      (distance > 0)
    ) {
      if (proxLocations === '') {
        proxLocations =
          store.getters['moduleBonusLocations/BonusLocationsRidingOnlyGetter'][
            bonuslocation
          ].name
      } else {
        proxLocations = `${proxLocations},${store.getters['moduleBonusLocations/BonusLocationsRidingOnlyGetter'][bonuslocation].name}`
      }
    }
  }
  if (proxLocations !== '') {
    proxLocations = `${
      store.getters['moduleBonusLocations/BonusLocationsByNameGetter'](
        this.name
      ).name
    },${proxLocations}`
    proxLocations = proxLocations.split(',')
    proxLocations.sort()
    proxLocations = proxLocations.toString()
  }
  this.proximity = proxLocations
  return true
}

/**
 * @author Patrick Nijsters
 * @returns {String}
 */
BonusLocationsPrototype.prototype.getSymbolcolor = function () {
  if (this.symbol === null || this.symbol.search(',') === -1) return 'black'
  return this.symbol.split(',')[1].trim()
}

/**
 * @author Patrick Nijsters
 * @returns {String}
 */
BonusLocationsPrototype.prototype.getMaterialicon = function () {
  if (this.symbol === null) return 'mdi-alert-circle'
  return MATERIALICONSYMBOLS[this.symbol.split(',')[0].trim()]
}

/**
 * @author Patrick Nijsters
 * @returns {String}
 */
BonusLocationsPrototype.prototype.getGarminicon = function () {
  if (this.symbol === null) return null
  let symbolcode =
    GARMINSYMBOLS[this.symbol.split(',')[0].trim()][
      this.symbol.split(',')[1].trim()
    ]
  return `https://firebasestorage.googleapis.com/v0/b/rally-bonus-planner.appspot.com/o/icons%2Fwpt_${symbolcode}.png?alt=media`
}

/**
 * @author Patrick Nijsters
 * @returns {String}
 */
BonusLocationsPrototype.prototype.getTshirtsize = function () {
  switch (this.value) {
    case 'small':
      return 'S'
    case 'medium':
      return 'M'
    case 'large':
      return 'L'
    case 'extra large':
      return 'XL'
  }
}

/**
 * @author Patrick Nijsters
 * @returns {String}
 */
BonusLocationsPrototype.prototype.getCategorylong = function () {
  switch (this.category) {
    case 'AC' | 'A':
      return 'all day'
    case 'DC' | 'D':
      return 'daylight'
    case 'TC' | 'T':
      return 'timerestricted'
    case 'S':
      return 'checkpoint'
    case 'N':
      return 'nonriding'
    default:
      return 'unknown'
  }
}

/**
 * @author Patrick Nijsters
 * @returns {Boolean}
 */
BonusLocationsPrototype.prototype.setCombototalpoints = function () {
  if (!this.combostops) {
    this.combototalpoints = this.points
    return false
  }
  let totalpoints = Number(this.points)
  let comboarray = this.combostops.split(',')
  for (let index in comboarray) {
    totalpoints =
      totalpoints +
      Number(
        store.getters['moduleBonusLocations/BonusLocationsByNameGetter'](
          comboarray[index]
        ).points
      )
  }
  this.combototalpoints = totalpoints
  return true
}

/**
 * @author Patrick Nijsters
 * @returns {Boolean}
 */
BonusLocationsPrototype.prototype.setCombocategory = function () {
  if (!this.combostops) return ''
  let combocategory = 'AC'
  let combosymbol = 'letterC, green'
  let result = this.combostops.split(',')
  for (let index in result) {
    if (
      store.getters['moduleBonusLocations/BonusLocationsByNameGetter'](
        result[index].trim()
      ).category === 'D' &&
      combocategory === 'AC'
    ) {
      combocategory = 'DC'
      combosymbol = 'letterC, blue'
    }
    if (
      store.getters['moduleBonusLocations/BonusLocationsByNameGetter'](
        result[index].trim()
      ).category === 'T'
    ) {
      combocategory = 'TC'
      combosymbol = 'letterC, red'
    }
  }
  this.category = combocategory
  this.symbol = combosymbol
  return true
}
