/**
 * @module moduleRallies
 */

import { db } from '@/firebaseConfig.js'
import { RalliesPrototype } from '@/components/prototypes/rallies.js'

const moduleRallies = {
  namespaced: true,
  state: {
    Rallies: []
  },
  mutations: {
    /**
     * @function RalliesReadMutation
     * @author Patrick Nijsters
     * @description Vuex **mutation** that stores the Rallies object passed to it in the Vuex state store
     * @memberof module:moduleRallies
     * @param {Object} _state Vuex state object
     * @param {Preferences} _preferences Rallies object
     * @returns {Void}
     */
    RalliesReadMutation: (_state, _rally) => {
      _state.Rallies.push(_rally)
    },

    /**
     * @function RalliesClearMutation
     * @author Patrick Nijsters
     * @description Vuex **mutation** that deletes all Rallies objects from the Vuex state store
     * @memberof module:moduleRallies
     * @param {Object} _state Vuex state object
     * @returns {Void}
     */
    RalliesClearMutation: (_state) => {
      _state.Rallies = []
    },

    /**
     * @function RalliesDeleteMutation
     * @author Patrick Nijsters
     * @description Vuex **mutation** that deletes a single rally from the state store based on the Rallies object passed to it
     * @memberof module:moduleRallies
     * @param {Object} _state Vuex state object
     * @param {Rallies} _rally Rally object
     * @returns {Void}
     * @todo Shouldnt we change this to use the rallyid to find and delete the object instead of matching the entire object?
     */
    RalliesDeleteMutation: (_state, _rallyid) => {
      const rally = _state.Rallies.find((rally) => rally.rallyid === _rallyid)
      const index = _state.Rallies.indexOf(rally)
      _state.Rallies.splice(index, 1)
    },

    /**
     * @function RalliesCreateUpdateMutation
     * @author Patrick Nijsters
     * @description Vuex **mutation** that either updates a single rally's Rallies object if it already exists or creates a new entry in the Vuex state store
     * @memberof module:moduleRallies
     * @param {VuexContext} _state Vuex state object
     * @param {Rallies} _rally Rally object
     * @returns {Void}
     */
    RalliesCreateUpdateMutation: (_state, _rally) => {
      const result = _state.Rallies.find(
        (rally) => rally.rallyid === _rally.rallyid
      )
      if (result) {
        Object.assign(result, JSON.parse(JSON.stringify(_rally)))
      } else {
        _state.Rallies.push(_rally)
      }
    }
  },
  actions: {
    /**
     * @function RalliesDeleteAction
     * @author Patrick Nijsters
     * @description Vuex **action** which
     * 1. Removes a specific rally from the Firestore using the Firestore API
     * 2. Removes a specific rally from the Vuex state store using a Vuex mutation
     * @memberof module:moduleRallies
     * @param {VuexContext} _context Vuex context {state, rootState, commit, dispatch, getters, rootGetters}
     * @param {UUIDv4} _rally Globally unique UUIDv4 rally object ID
     * @returns {Void}
     * @see module:moduleRallies.RalliesDeleteMutation
     */
    async RalliesDeleteAction(_context, _rallyid) {
      await db
        .collection(
          `users/${_context.rootState.moduleUser.CurrentUser.uid}/rallies`
        )
        .doc(_rallyid)
        .delete()
      _context.commit('RalliesDeleteMutation', _rallyid)
    },

    /**
     * @function RalliesReadAllAction
     * @author Patrick Nijsters
     * @description Vuex **action** which
     * 1. Clears all rallies from the Vuex state store
     * 2. Uses the Firestore API to read all rallies from the Firestore
     * 3. For each rally found in the Firestore, calls a Vuex mutation to add this rally to the Vuex state store
     * @memberof module:moduleRallies
     * @param {VuexContext} _context Vuex context {state, rootState, commit, dispatch, getters, rootGetters}
     * @returns {Void}
     * @see module:moduleRallies.RalliesReadMutation
     */
    async RalliesReadAllAction(_context) {
      _context.commit('RalliesClearMutation')
      await db
        .collection(
          `users/${_context.rootState.moduleUser.CurrentUser.uid}/rallies`
        )
        .get()
        .then(function (result) {
          for (let document in result.docs) {
            _context.commit('RalliesReadMutation', result.docs[document].data())
          }
        })
    },

    /**
     * @function RalliesClearAction
     * @author Patrick Nijsters
     * @description Vuex **action** that deletes all Rallies objects from the Vuex state store
     * @memberof module:moduleRallies
     * @param {VuexContext} _context Vuex context {state, rootState, commit, dispatch, getters, rootGetters}
     * @returns {Void}
     * @see module:moduleRallies.RalliesClearMutation
     */
    RalliesClearAction: (_context) => {
      _context.commit('RalliesClearMutation')
    },

    /**
     * @function RalliesCreateUpdateAction
     * @author Patrick Nijsters
     * @description Vuex **action** which
     * 1. Updates or inserts a rally in the Firestore using the Firestore API
     * 2. Updates or inserts the same rally in the Vuex state store using a Vuex mutation
     * @memberof module:moduleRallies
     * @param {VuexContext} _context Vuex context {state, rootState, commit, dispatch, getters, rootGetters}
     * @param {Rallies} _rally
     * @returns {Void}
     * @see module:moduleRallies.RalliesCreateUpdateMutation
     */
    async RalliesCreateUpdateAction(_context, _rally) {
      await db
        .collection(
          `users/${_context.rootState.moduleUser.CurrentUser.uid}/rallies`
        )
        .doc(_rally.rallyid)
        .set(JSON.parse(JSON.stringify(_rally)))
      _context.commit('RalliesCreateUpdateMutation', _rally)
    }
  },
  getters: {
    RalliesGetRallyByIdGetter: (_state) => (_rallyid) => {
      const activerally = _state.Rallies.find(
        (rally) => rally.rallyid === _rallyid
      )
      if (!activerally) return new RalliesPrototype()
      activerally.__proto__ = RalliesPrototype.prototype
      return activerally
    }
  }
}

/**
 * @description Vuex module for Rallies
 */
export default moduleRallies
