/**
 * @module moduleRouteService
 */

/**
 * @typedef {Object} RouteServiceCacheEntry
 * @author Patrick Nijsters
 * @global
 * @property {String} index The cache index is a string separated (no whitespace) concatenation of the origin and destination bonus location
 * @property {Object} value The route calculation result object
 * @property {Number} value.distance The calculated distance, in miles, as returned by the ORS routeservice API
 * @property {Duration} value.duration THe calculated time, in hours, as returned by the ORS routeservice API
 * @property {Number} value.timestamp Epoch timestamp of when the ORS routeservice API was queried
 */

import axios from 'axios'
import LatLon from 'geodesy/latlon-ellipsoidal-vincenty.js'

const moduleRouteService = {
  namespaced: true,
  state: {
    RouteServiceCache: []
  },
  mutations: {
    /**
     * @function RouteServiceClearMutation
     * @description Vuex **mutation** that clears the entire Vuex state store
     * @author Patrick Nijsters
     * @memberof module:moduleRouteService
     * @param {VuexContext} _context Vuex state object
     */
    RouteServiceClearMutation: (_context) => {
      _context.RouteServiceCache = []
    },

    /**
     * @function RouteServiceGetDirectionsActionMutation
     * @description Vuex **mutation** that either updates a cache entry (if found) or inserts a new cache entry into the Vuex state store
     * @author Patrick Nijsters
     * @memberof module:moduleRouteService
     * @param {VuexContext} _context Vuex state object
     * @param {*} _direction
     */
    RouteServiceGetDirectionsActionMutation: (_context, _direction) => {
      const item = _context.RouteServiceCache.find(
        (directionupdate) => directionupdate.index === _direction.index
      )
      if (item) {
        _context.RouteServiceCache.splice(
          _context.RouteServiceCache.indexOf(item),
          1,
          _direction
        )
      } else {
        _context.RouteServiceCache.push(_direction)
      }
    }
  },
  actions: {
    /**
     * @function RouteServiceClearAction
     * @description Vuex **action** that uses a Vuex mutation to clear the Vuex state store
     * @author Patrick Nijsters
     * @memberof module:moduleRouteService
     * @param {VuexContext} _context Vuex state object
     */
    async RouteServiceClearAction(_context) {
      _context.commit('RouteServiceClearMutation')
    },

    /**
     * @function RouteServiceGetDirectionsAction
     * @description Vuex **action** that checks if there is a route calculation between the two specified routestops in the cache, if so it return that as a result. Otherwise the Axios API is used to query Open Route Service for the route calculation. The ORS route calculation is done in both directions and both results are stored in the cache for future reference
     * @author Patrick Nijsters
     * @memberof module:moduleRouteService
     * @param {VuexContext} _context Vuex state object
     * @param {Object} _locations An object that contains two Bonus Locations objects in the form of {origin: {Bonus Locations}, destination: {Bonus Locations}}
     * @requires Axios
     * @requires LatLon
     * @returns {String}
     */
    async RouteServiceGetDirectionsAction(_context, _locations) {
      // calculate the cache indices
      let cacheIndexForward = `${_locations.origin.name},${_locations.destination.name}`
      let cacheIndexReverse = `${_locations.destination.name},${_locations.origin.name}`
      // check the cache if we already have this route calculation
      let cachedItem =
        _context.rootState.moduleRouteService.RouteServiceCache.find(
          (direction) => direction.index === cacheIndexForward
        )
      if (cachedItem) return cachedItem.value // found a cache entry, we're done

      // no cache entry found, need to query ORS for a route calculation
      const ORSURL =
        'https://api.openrouteservice.org/v2/directions/driving-car'
      const AXIOSHEADERS = {
        'Content-Type': 'application/json; charset=utf-8',
        Accept:
          'application/json, application/geo+json, application/gpx+xml, img/png; charset=utf-8',
        Authorization:
          _context.rootState.modulePreferences.Preferences.secrets.ORS_API_KEY
      }

      // ORS route calculation can calculate the forward and reverse cache in one API call provided the total distance is below 6000km. We use the vinccenty calculation to get a rough estimate to decide if we can do a single API call or need to break the query into two API calls
      let vincentyDistance =
        new LatLon(
          _locations.origin.latitude,
          _locations.origin.longitude
        ).distanceTo(
          new LatLon(
            _locations.destination.latitude,
            _locations.destination.longitude
          )
        ) / 1609
      let valueForward = null
      let valueReverse = null
      let axiosresult = null
      if (vincentyDistance > 1800) {
        // two separate API calls to stay under the 6000km limit of the ORS API service
        axiosresult = await axios
          .post(
            ORSURL,
            {
              coordinates: [
                [
                  Number(_locations.origin.longitude),
                  Number(_locations.origin.latitude)
                ],
                [
                  Number(_locations.destination.longitude),
                  Number(_locations.destination.latitude)
                ]
              ],
              units: 'mi',
              geometry: true,
              elevation: false
            },
            {
              headers: AXIOSHEADERS
            }
          )
          .catch(function (error) {
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.error(error.response.data.error.message)
              //console.error(error.response.data)
              // console.error(error.response.status)
              // console.error(error.response.headers)
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.error(error.request)
            } else {
              // Something happened in setting up the request that triggered an Error
              console.error('Error', error.message)
            }
            console.error(error.config)
          })
        valueForward = {
          distance: axiosresult.data.routes[0].summary.distance,
          duration:
            axiosresult.data.routes[0].summary.duration /
            3600 /
            _context.rootState.modulePreferences.Preferences.routing
              .average_speed_adjustment,
          timestamp: Date.now(),
          geometry: axiosresult.data.routes[0].geometry
        }
        axiosresult = await axios
          .post(
            ORSURL,
            {
              coordinates: [
                [
                  Number(_locations.destination.longitude),
                  Number(_locations.destination.latitude)
                ],
                [
                  Number(_locations.origin.longitude),
                  Number(_locations.origin.latitude)
                ]
              ],
              units: 'mi',
              geometry: true,
              elevation: false
            },
            {
              headers: AXIOSHEADERS
            }
          )
          .catch(function (error) {
            if (error.response) {
              console.error(error.response.data.error.message)
            } else if (error.request) {
              console.error(error.request)
            } else {
              console.error('Error', error.message)
            }
            console.error(error.config)
          })
        valueReverse = {
          distance: axiosresult.data.routes[0].summary.distance,
          duration:
            axiosresult.data.routes[0].summary.duration /
            3600 /
            _context.rootState.modulePreferences.Preferences.routing
              .average_speed_adjustment,
          timestamp: Date.now(),
          geometry: axiosresult.data.routes[0].geometry
        }
      } else {
        axiosresult = await axios
          .post(
            ORSURL,
            {
              coordinates: [
                [
                  Number(_locations.origin.longitude),
                  Number(_locations.origin.latitude)
                ],
                [
                  Number(_locations.destination.longitude),
                  Number(_locations.destination.latitude)
                ],
                [
                  Number(_locations.origin.longitude),
                  Number(_locations.origin.latitude)
                ]
              ],
              units: 'mi',
              geometry: true,
              elevation: false
            },
            {
              headers: AXIOSHEADERS
            }
          )
          .catch(function (error) {
            if (error.response) {
              console.error(error.response.data.error.message)
            } else if (error.request) {
              console.error(error.request)
            } else {
              console.error('Error', error.message)
            }
            console.error(error.config)
          })

        let decode = require('@googlemaps/polyline-codec').decode
        let encode = require('@googlemaps/polyline-codec').encode
        let decodedGeo = decode(axiosresult.data.routes[0].geometry)

        valueForward = {
          distance: axiosresult.data.routes[0].segments[0].distance,
          duration:
            axiosresult.data.routes[0].segments[0].duration /
            3600 /
            _context.rootState.modulePreferences.Preferences.routing
              .average_speed_adjustment,
          timestamp: Date.now(),
          geometry: encode(decodedGeo.slice(1, decodedGeo.length / 2))
        }
        valueReverse = {
          distance: axiosresult.data.routes[0].segments[1].distance,
          duration:
            axiosresult.data.routes[0].segments[1].duration /
            3600 /
            _context.rootState.modulePreferences.Preferences.routing
              .average_speed_adjustment,
          timestamp: Date.now(),
          geometry: encode(decodedGeo.slice(decodedGeo.length / 2 + 1))
        }
      }
      // store all of our results in the cache for future reference
      _context.commit('RouteServiceGetDirectionsActionMutation', {
        index: cacheIndexForward,
        value: valueForward
      })
      _context.commit('RouteServiceGetDirectionsActionMutation', {
        index: cacheIndexReverse,
        value: valueReverse
      })
      return valueForward
    }
  }
}

/**
 * @description Vuex module for Open Route Service calculations
 */
export default moduleRouteService
