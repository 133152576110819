export const GARMINSYMBOLS = {
  diamond: { display: 'diamond', red: '3', green: '2', blue: '168' },
  square: { display: 'square', red: '16', green: '15', blue: '175' },
  circle: { display: 'circle', red: '165', green: '166', blue: '167' },
  triangle: {
    display: 'triangle',
    red: '220',
    green: '219',
    blue: '218'
  },
  flag: { display: 'flag', red: '140', green: '142', blue: '141' },
  pin: { display: 'pin', red: '143', green: '145', blue: '144' },
  oval: { display: 'oval', red: '169', green: '170', blue: '171' },
  block: { display: 'block', red: '146', green: '148', blue: '147' },
  rectangle: {
    display: 'rectangle',
    red: '172',
    green: '173',
    blue: '174'
  },
  letterA: { display: 'letter A', red: '176', green: '177', blue: '178' },
  letterB: { display: 'letter B', red: '179', green: '180', blue: '181' },
  letterC: { display: 'letter C', red: '182', green: '183', blue: '184' },
  letterD: { display: 'letter D', red: '185', green: '186', blue: '187' },
  number0: { display: 'number 0', red: '188', green: '189', blue: '190' },
  number1: { display: 'number 1', red: '191', green: '192', blue: '193' },
  number2: { display: 'number 2', red: '194', green: '195', blue: '196' },
  number3: { display: 'number 3', red: '197', green: '198', blue: '199' },
  number4: { display: 'number 4', red: '200', green: '201', blue: '202' },
  number5: { display: 'number 5', red: '203', green: '204', blue: '205' },
  number6: { display: 'number 6', red: '206', green: '207', blue: '208' },
  number7: { display: 'number 7', red: '209', green: '210', blue: '211' },
  number8: { display: 'number 8', red: '212', green: '213', blue: '214' },
  number9: { display: 'number 9', red: '215', green: '216', blue: '217' },
  unknown: { display: 'unknown', red: '253', green: '253', blue: '253' }
}
export const MATERIALICONSYMBOLS = {
  diamond: 'mdi-diamond',
  square: 'mdi-cube',
  circle: 'mdi-circle',
  triangle: 'mdi-triangle',
  flag: 'mdi-flag',
  pin: 'mdi-pin',
  oval: 'mdi-ellipse',
  block: 'mdi-cube',
  rectangle: 'mdi-rectangle',
  letterA: 'mdi-alpha-a',
  letterB: 'mdi-alpha-b',
  letterC: 'mdi-alpha-c',
  letterD: 'mdi-alpha-d',
  number0: 'mdi-numeric-0',
  number1: 'mdi-numeric-1',
  number2: 'mdi-numeric-2',
  number3: 'mdi-numeric-3',
  number4: 'mdi-numeric-4',
  number5: 'mdi-numeric-5',
  number6: 'mdi-numeric-6',
  number7: 'mdi-numeric-7',
  number8: 'mdi-numeric-8',
  number9: 'mdi-numeric-9',
  unknown: 'mdi-map-marker-question',
  rest: 'mdi-sleep',
  tracking: 'mdi-radar',
  'call-in': 'mdi-cellphone',
  gas: 'mdi-gas-station',
  meal: 'mdi-silverware-fork-knife',
  other: 'mdi-expand-all-outline'
}
