/**
 * @module moduleNotifications
 * @author Patrick Nijsters
 */

const moduleNotifications = {
  namespaced: true,
  state: {
    Notifications: []
  },
  mutations: {
    /**
     * @function NotificationsClearMutation
     * @author Patrick Nijsters
     * @description Vuex **mutation** that deletes all notifications from the Vuex state
     * @memberof module:moduleNotifications
     * @param {Object} _state Vuex state object
     * @returns {Void}
     */
    NotificationsClearMutation: (_state) => {
      _state.Notifications = []
    },

    /**
     * @function NotificationsCreateUpdateMutation
     * @author Patrick Nijsters
     * @description Vuex **mutation** that updates an existing notification. It searches for the notification based on timestamp (the assumption is that no two notifications ever have the exact same timestamp) and then replaces the entire recordt that was found with the new record. If no matching notification was found, a new notification is inserted into the Vuex state object.
     * @memberof module:moduleNotifications
     * @param {Object} _state Vuex state object
     * @returns {Void}
     */
    NotificationsCreateUpdateMutation: (_state, _notifications) => {
      const item = _state.Notifications.find(
        (notificationsupdate) =>
          notificationsupdate.time === _notifications.time
      )
      if (item) {
        _state.Notifications.splice(
          _state.Notifications.indexOf(item),
          1,
          _notifications
        )
      } else {
        _state.Notifications.push(_notifications)
      }
    }
  },
  actions: {
    /**
     * @function NotificationsCreateUpdateAction
     * @author Patrick Nijsters
     * @description Vuex **action** which calls a Vuex mutation that either updates an existing notification (if found), or creates a new notification (if not found)
     * @memberof module:moduleNotifications
     * @param {VuexContext} _context Vuex context {state, rootState, commit, dispatch, getters, rootGetters}
     * @param {Notification} _notification Notification object
     * @returns {Void}
     */
    async NotificationsCreateUpdateAction(_context, _notification) {
      _context.commit(
        'NotificationsCreateUpdateMutation',
        JSON.parse(JSON.stringify(_notification))
      )
    },

    /**
     * @function NotificationsClearAction
     * @author Patrick Nijsters
     * @description Vuex **action** which calls a Vuex mutation that clears ('deletes') all notifications
     * @memberof module:moduleNotifications
     * @param {VuexContext} _context Vuex context {state, rootState, commit, dispatch, getters, rootGetters}
     * @returns {Void}
     */
    async NotificationsClearAction(_context) {
      _context.commit('NotificationsClearMutation')
    }
  },
  getters: {
    /**
     * @function NotificationsCountGetter
     * @author Patrick Nijsters
     * @description Vuex **getter** that returns the total number of notifications
     * @memberof module:moduleNotifications
     * @param {Object} _state Vuex state object
     * @returns {Number} The total number of notifications
     */
    NotificationsCountGetter: (_state) => {
      let result = _state.Notifications.filter(
        (notification) => notification.acknowledged === false
      )
      return result.length
    },

    /**
     * @function NotificationsNewestFirstGetter
     * @author Patrick Nijsters
     * @description Vuex **getter** that returns a sorted array (by time, newest first) of Notification objects
     * @memberof module:moduleNotifications
     * @param {Object} _state Vuex state object
     * @returns {Array<Notification>} Array of time sorted (newest first) Notification objects
     */
    NotificationsNewestFirstGetter: (_state) => {
      let sortedNotifications = JSON.parse(JSON.stringify(_state.Notifications))
      return sortedNotifications.sort((a, b) => (a.time < b.time ? 1 : -1))
    }
  }
}

/**
 * @description Vuex module for Notifications
 */
export default moduleNotifications
