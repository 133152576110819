<template>
  <v-app id="app">
    <v-navigation-drawer
      v-model="drawerNotifications"
      absolute
      temporary
      right
      width="600"
    >
      <v-toolbar color="blue" flat dense>
        <v-toolbar-title class="white--text">Notifications </v-toolbar-title>
        <v-spacer />
        <v-btn
          tabindex="-1"
          icon
          @click.stop="toggleNotificationsAckowledgedAll"
        >
          <v-icon color="white">mdi-check-all</v-icon>
        </v-btn>
        <v-btn tabindex="-1" icon @click.stop="NotificationsClearAction">
          <v-icon color="white">mdi-delete-empty</v-icon>
        </v-btn>
        <v-btn
          tabindex="-1"
          icon
          @click.stop="drawerNotifications = !drawerNotifications"
        >
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card v-if="showNotificationsDetailsCard" flat color="blue lighten-5">
        <v-card-title>
          <v-icon
            v-if="itemNotificationsDetail.level === 'information'"
            color="blue
            "
            class="pr-1"
            >mdi-information</v-icon
          >
          <v-icon
            v-if="itemNotificationsDetail.level === 'warning'"
            color="orange"
            class="pr-1"
            >mdi-alert</v-icon
          >
          <v-icon
            v-if="itemNotificationsDetail.level === 'critical'"
            color="red"
            class="pr-1"
            >mdi-alert-circle</v-icon
          >

          {{ new Date(Number(itemNotificationsDetail.time)).toLocaleString() }}
        </v-card-title>
        <v-card-subtitle class="ml-7"
          >[{{ itemNotificationsDetail.source }}]</v-card-subtitle
        >
        <v-card-text>
          {{ itemNotificationsDetail.message }}
        </v-card-text>
        <v-card-actions>
          <v-row align="center" justify="space-around" class="mb-2">
            <v-btn
              color="blue"
              class="white--text"
              tabindex="-1"
              @click.stop="showNotificationsDetailsCard = false"
            >
              <v-icon left> mdi-eye-off </v-icon>
              Dismiss
            </v-btn></v-row
          >
        </v-card-actions></v-card
      >
      <v-divider />
      <v-virtual-scroll
        :items="NotificationsNewestFirstGetter"
        item-height="100"
      >
        <template v-slot:default="{ item }">
          <v-list-item :key="item.time">
            <v-list-item-content
              :class="item.acknowledged === true ? 'grey--text' : 'black-text'"
            >
              <v-list-item-title
                ><v-icon
                  v-if="item.level === 'information'"
                  small
                  :color="item.acknowledged === false ? 'blue' : 'grey'"
                  class="pr-1"
                  >mdi-information</v-icon
                >
                <v-icon
                  v-if="item.level === 'warning'"
                  small
                  :color="item.acknowledged === false ? 'orange' : 'grey'"
                  class="pr-1"
                  >mdi-alert</v-icon
                >
                <v-icon
                  v-if="item.level === 'critical'"
                  small
                  :color="item.acknowledged === false ? 'red' : 'grey'"
                  class="pr-1"
                  >mdi-alert-circle</v-icon
                >
                <strong
                  >{{ new Date(Number(item.time)).toLocaleString() }} - [{{
                    item.source
                  }}]</strong
                >
              </v-list-item-title>

              {{
                item.message.slice(0, 116) +
                (item.message.length > 116 ? '...' : '')
              }}
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                v-if="item.message.length > 116 ? true : false"
                icon
                @click="showNotificationsDetails(item)"
                ><v-icon>mdi-magnify-plus-outline</v-icon>
              </v-btn>
              <v-btn icon @click="toggleNotificationsAckowledged(item)"
                ><v-icon>mdi-check</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
        </template>
      </v-virtual-scroll>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-if="CurrentUser"
      v-model="drawerMenu"
      app
      color="grey lighten-5"
      expand-on-hover
      mini-variant
      clipped
    >
      <v-list dense>
        <v-list-item tabindex="-1" dense :to="{ name: 'Home' }" link>
          <v-list-item-action>
            <v-icon>mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          tabindex="-1"
          :disabled="!UserProfile.activerallyid"
          dense
          :to="{ name: 'FileImport' }"
          link
        >
          <v-list-item-action>
            <v-icon>mdi-import</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>File import</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          tabindex="-1"
          :disabled="!UserProfile.activerallyid"
          dense
          :to="{ name: 'RallyBook' }"
          link
        >
          <v-list-item-action>
            <v-icon>mdi-book-open-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Rally book</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          tabindex="-1"
          :disabled="!UserProfile.activerallyid"
          dense
          :to="{ name: 'Bonuses' }"
          link
        >
          <v-list-item-action>
            <v-icon>mdi-map-marker</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Bonus locations</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          tabindex="-1"
          :disabled="!UserProfile.activerallyid"
          dense
          :to="{ name: 'Combos' }"
          link
        >
          <v-list-item-action>
            <v-icon>mdi-group</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Combos & clusters</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          tabindex="-1"
          :disabled="!UserProfile.activerallyid"
          dense
          :to="{ name: 'PointCategories' }"
          link
        >
          <v-list-item-action>
            <v-icon>mdi-chart-bell-curve</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Point categories</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          tabindex="-1"
          :disabled="!UserProfile.activerallyid"
          dense
          :to="{ name: 'LegPlan' }"
          link
        >
          <v-list-item-action>
            <v-icon>mdi-file-document-edit</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Leg planning</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          tabindex="-1"
          :disabled="!UserProfile.activerallyid"
          dense
          :to="{ name: 'RoutePlan' }"
          link
        >
          <v-list-item-action>
            <v-icon>mdi-routes-clock</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Route planning</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          tabindex="-1"
          :disabled="!UserProfile.activerallyid"
          dense
          :to="{ name: 'FileExport' }"
          link
        >
          <v-list-item-action>
            <v-icon>mdi-export</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>File export</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          tabindex="-1"
          :disabled="!UserProfile.activerallyid"
          dense
          :to="{ name: 'IBARides' }"
          link
        >
          <v-list-item-action>
            <v-icon>mdi-motorbike</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>IBA rides</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar v-if="CurrentUser" app color="grey lighten-3" flat clipped-left>
      <v-app-bar-nav-icon
        tabindex="-1"
        @click.stop="drawerMenu = !drawerMenu"
      />
      <v-toolbar-title>LD Rally and Ride Planner - v0.2 (Beta)</v-toolbar-title>
      <v-spacer />
      <v-btn
        tabindex="-1"
        icon
        @click.stop="drawerNotifications = !drawerNotifications"
      >
        <v-badge
          :content="NotificationsCountGetter"
          :value="NotificationsCountGetter"
          color="red"
          overlap
        >
          <v-icon>mdi-bell</v-icon></v-badge
        >
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" tabindex="-1" v-on="on">
            <v-icon>mdi-help-circle</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item :to="{ name: 'Repair' }">
            <v-list-item-icon>
              <v-icon>mdi-database-search</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Check database</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'About' }">
            <v-list-item-icon>
              <v-icon>mdi-information</v-icon>
            </v-list-item-icon>
            <v-list-item-title>About</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" tabindex="-1" v-on="on">
            <v-avatar color="grey darken-1" size="28">
              <span class="white--text">{{ avatarName }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon x-large>mdi-account</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ UserProfile.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ UserProfile.email }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list>
            <v-list-item :to="{ name: 'ChangePassword' }">
              <v-list-item-icon>
                <v-icon>mdi-lock</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Change password</v-list-item-title>
            </v-list-item>
            <v-list-item :to="{ name: 'Preferences' }">
              <v-list-item-icon>
                <v-icon>mdi-cog-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Preferences</v-list-item-title>
            </v-list-item>
            <v-list-item link @click.prevent="signOut">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Sign Out</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-main>
      <v-dialog v-if="isIdle && CurrentUser" v-model="dialog" width="500">
        <v-card>
          <v-card-title class="headline red white--text" dark
            ><v-icon color="white" large class="mr-5">mdi-timer-outline</v-icon>
            Inactivity timeout
          </v-card-title>
          <v-card-text class="mt-12">
            <p>
              Your sessions will expire in {{ idleRemainingSeconds }} seconds.
              Move the mouse or hit any key to continue the session. Otherwise
              you will be logged out automatically
            </p>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer v-if="CurrentUser" app>
      <div class="caption">
        Copyright &copy; 2021 Patrick Nijsters. This program comes with
        ABSOLUTELY NO WARRANTY.
      </div>
      <v-spacer></v-spacer>
      <div class="caption">
        {{ showFooter() }}
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
const Firebase = require('./firebaseConfig.js')

export default {
  name: 'App',
  props: {
    source: { type: String, default: '' }
  },
  data() {
    return {
      itemNotificationsDetail: {},
      showNotificationsDetailsCard: false,
      drawerMenu: null,
      drawerNotifications: null,
      dialog: true,
      inactivityDelay: 60000
    }
  },
  computed: {
    ...mapState({
      CurrentUser: (state) => state.moduleUser.CurrentUser,
      UserProfile: (state) => state.moduleUser.UserProfile,
      Preferences: (state) => state.modulePreferences.Preferences,
      Notifications: (state) => state.moduleNotifications.Notifications,
      Rallies: (state) => state.moduleRallies.Rallies
    }),
    ...mapGetters('moduleNotifications', [
      'NotificationsCountGetter',
      'NotificationsNewestFirstGetter'
    ]),
    activeRally() {
      const item = this.Rallies.find(
        (rally) => rally.rallyid === this.UserProfile.activerallyid
      )
      return item
    },
    isIdle() {
      return this.$store.state.idleVue.isIdle
    },
    idleRemainingSeconds() {
      return this.inactivityDelay / 1000
    },
    avatarName() {
      if (this.UserProfile.name) return this.UserProfile.name.substr(0, 1)
      return ''
    }
  },
  watch: {
    isIdle() {
      if (this.isIdle === true) {
        let timerId = setInterval(() => {
          this.inactivityDelay -= 1000
          if (!this.$store.state.idleVue.isIdle) clearInterval(timerId)
          if (this.inactivityDelay < 1) {
            clearInterval(timerId)
            this.signOut()
          }
        }, 1000)
      } else {
        this.inactivityDelay = 10000
      }
    }
  },
  methods: {
    ...mapActions('moduleNotifications', [
      'NotificationsClearAction',
      'NotificationsCreateUpdateAction'
    ]),
    ...mapActions('moduleUser', ['UserProfileSetActiveRallyAction']),
    showNotificationsDetails(_notification) {
      this.itemNotificationsDetail = _notification
      this.showNotificationsDetailsCard = true
    },
    async toggleNotificationsAckowledged(_notification) {
      _notification.acknowledged = !_notification.acknowledged
      this.NotificationsCreateUpdateAction(_notification)
    },
    async toggleNotificationsAckowledgedAll() {
      for (let item in this.NotificationsNewestFirstGetter) {
        this.NotificationsNewestFirstGetter[item].acknowledged = true
        this.NotificationsCreateUpdateAction(
          this.NotificationsNewestFirstGetter[item]
        )
      }
    },
    showFooter() {
      let rallyname = null
      let username = null
      if (this.activeRally === undefined) {
        rallyname = ''
      } else {
        rallyname =
          'Leg ' + this.activeRally.legid + ' of ' + this.activeRally.rallyname
      }
      if (this.UserProfile.name) {
        username = `(${this.UserProfile.name})`
      } else {
        username = ''
      }
      return `${rallyname} ${username}`
    },
    async signOut() {
      await this.UserProfileSetActiveRallyAction('')
      Firebase.auth
        .signOut()
        .then(() => {
          this.$router.push('/login', () => {})
          this.$store.dispatch('moduleUser/UserProfileClearAction')
          this.$store.dispatch('moduleUser/CurrentUserClearAction')
          this.$store.dispatch('modulePreferences/PreferencesClearAction')
          this.$store.dispatch('moduleRallies/RalliesClearAction')
          this.$store.dispatch('moduleNotifications/NotificationsClearAction')
          this.$store.dispatch('moduleBonusLocations/BonusLocationsClearAction')
          this.$store.dispatch('moduleRouteStops/RouteStopsClearAction')
          this.$store.dispatch('moduleLegPlan/LegPlanClearAction')
          this.$store.dispatch('moduleRouteService/RouteServiceClearAction')
        })
        .catch((error) => {
          console.error(error)
        })
      localStorage.clear()
    }
  }
}
</script>
