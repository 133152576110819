import Vue from 'vue'
import Router from 'vue-router'
import Firebase from 'firebase/app'

Vue.use(Router)

function loadView(view) {
  // enables lazy loading of components by splitting up the Java bundles into multiple smaller bundles that load faster and on-demand
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: loadView('Home'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: loadView('Login')
  },
  {
    path: '/rally',
    name: 'Preferences',
    component: loadView('Preferences'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/import',
    name: 'FileImport',
    component: loadView('FileImport'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/rallybook',
    name: 'RallyBook',
    component: loadView('RallyBook'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bonuses',
    name: 'Bonuses',
    component: loadView('Bonuses'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/combos',
    name: 'Combos',
    component: loadView('Combos'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/routes',
    name: 'RoutePlan',
    component: loadView('RoutePlan'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pointcategories',
    name: 'PointCategories',
    component: loadView('PointCategories'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/export',
    name: 'FileExport',
    component: loadView('FileExport'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/rides',
    name: 'IBARides',
    component: loadView('Rides'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/about',
    name: 'About',
    component: loadView('About'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/repair',
    name: 'Repair',
    component: loadView('Repair'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/password',
    name: 'ChangePassword',
    component: loadView('ChangePassword'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/legplan',
    name: 'LegPlan',
    component: loadView('LegPlan'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth)
  const CurrentUser = Firebase.auth().currentUser

  if (requiresAuth && !CurrentUser) {
    next('/login')
  } else if (requiresAuth && CurrentUser) {
    next()
  } else {
    next()
  }
})

export default router
