import store from '@/store'
import { DateTime } from 'luxon'
import { v4 as uuidv4 } from 'uuid'
import {
  parseTimeRestrictions,
  evaluateTimeRestrictions
} from '@/components/parseTimeRestrictions.js'

/**
 * @class
 * @author Patrick Nijsters
 * @global
 * @property {UUIDv4} stopid Globally unique identifier for this routestop
 * @property {String} name The short name of the route stop as defined in the rally book
 * @property {Number} order=0 The numerical order of the route stop in the route, starting at 0
 * @property {B|S} stoptype The type of the stop.
 * * B for Bonus Location
 * * S for Start or End location of rally
 * @property {Number} dwelltime The time in minutes spent out of the saddle at a stop (fueling, bathroom break, meal)
 * @property {Number} restbonusandtime The time, in minutes, spent resting at a stop (??)     *
 * @property {String} timezoneid The timezone of the location in its most descriptive format like "Americas/Chicago"
 * @property {Number} points the numerical point value of this stop as defined in the rally book
 * @property {Object} map
 * @property {Number} map.distance The planned distance, in miles, from the previous route stop
 * @property {Number} map.traveltime The planned travel time, in minutes, from the previous route stop
 * @property {Number} map.pointspermile The planned points per mile from the previous route stop calculated by taking the number of points earned for a stop divided by the distance from the previous stop
 * @property {ISO8601} map.arrivaltime The planned date and time of arrival at a stop
 * @property {ISO8601} map.departuretime The planned date and time of departure from a stop
 */
export function RouteStopsPrototype(
  stopid = null,
  name = null,
  order = null,
  stoptype = 'B',
  dwelltime = 0,
  restbonusandtime = null,
  timezoneid = null,
  points = 0,
  map = null
) {
  this.stopid = stopid
  this.name = name
  this.order = order
  this.stoptype = stoptype
  this.dwelltime = dwelltime
  this.restbonusandtime = restbonusandtime
  this.timezoneid = timezoneid
  this.points = points
  this.map = map
    ? map
    : {
        distance: 0,
        traveltime: 0,
        pointspermile: 0,
        arrivaltime: null,
        departuretime: null
      }
}
/**
 * @description This function is called when a new route is being initialized. This function creates either the START or END routestop based on the configured rally parameters (location, time, timezone)
 * @requires Luxon
 * @requires UUIDv4
 * @returns {Boolean}
 */
RouteStopsPrototype.prototype.initializeCheckpoint = function (
  _checkpointname = 'START'
) {
  _checkpointname = _checkpointname.toLowerCase()
  if (_checkpointname !== 'start' && _checkpointname !== 'end') return false
  let activerally = store.getters['moduleRallies/RalliesGetRallyByIdGetter'](
    store.state.moduleUser.UserProfile.activerallyid
  )
  this.stopid = uuidv4()
  this.name = _checkpointname.toUpperCase()
  this.order = _checkpointname === 'start' ? 1 : 2
  this.stoptype = 'S'
  this.dwelltime =
    _checkpointname === 'start'
      ? Number(activerally.onclockplanningtime) * 60
      : 0

  this.timezoneid = store.getters[
    'moduleBonusLocations/BonusLocationsByNameGetter'
  ](_checkpointname.toUpperCase()).timezoneid
  this.points = 0
  this.map.arrivaltime =
    _checkpointname === 'start'
      ? DateTime.fromISO(activerally.start.datetime).setZone(
          activerally.start.timezoneid
        )
      : DateTime.fromISO(activerally.end.datetime).setZone(
          activerally.end.timezoneid
        )

  this.map.departuretime =
    _checkpointname === 'start'
      ? DateTime.fromISO(this.map.arrivaltime)
          .setZone(this.timezoneid)
          .plus({
            minutes: Number(this.dwelltime)
          })
      : this.map.arrivaltime
  return true
}
/**
 * @description This function initializes a new routestop that is not a checkpoint based on rally and corresponding bonus location.
 * @returns {Boolean}
 */
RouteStopsPrototype.prototype.initializeStop = function () {
  this.stopid = uuidv4()
  this.order = Number(
    store.getters['moduleRouteStops/RouteStopsSortedByOrder'].length
  )
  this.dwelltime = Number(
    store.state.modulePreferences.Preferences.routing.bonus_stopduration
  )
  let bonuslocation = store.getters[
    'moduleBonusLocations/BonusLocationsByNameGetter'
  ](this.name)
  this.timezoneid = bonuslocation.timezoneid
  this.points = bonuslocation.points
  return true
}
/**
 * @description This function takes the Luxon DateTime object for the routestop and outputs a specifically formatted string for display
 * @param {String} _displayformat
 * @param {String} _time
 * @returns {String}
 */
RouteStopsPrototype.prototype.getFormattedtime = function (
  _displayformat = 'date',
  _time = 'arrival'
) {
  let time = this.map.arrivaltime
  if (_time === 'departure') time = this.map.departuretime
  if (_displayformat === 'date')
    return DateTime.fromISO(time)
      .setZone(this.timezoneid)
      .toFormat('MM/dd HH:mm ZZZZ')
  return DateTime.fromISO(time)
    .setZone(this.timezoneid)
    .toFormat('ccc HH:mm ZZZZ')
}
/**
 * @description This function return the material icon string for the route stop
 * @returns {String}
 */
RouteStopsPrototype.prototype.getMaterialicon = function () {
  return store.getters['moduleBonusLocations/BonusLocationsByNameGetter'](
    this.name
  ).getMaterialicon()
}
/**
 * @description This functions returns the color for the symbol icon for the route stop
 * @returns {String}
 */
RouteStopsPrototype.prototype.getSymbolcolor = function () {
  return store.getters['moduleBonusLocations/BonusLocationsByNameGetter'](
    this.name
  ).getSymbolcolor()
}
/**
 *
 * @returns {String}
 */
RouteStopsPrototype.prototype.getComboMemberships = function () {
  let combomemberships = store.getters[
    'moduleBonusLocations/BonusLocationsCombosConstituentsGetter'
  ].filter((bonuslocation) => bonuslocation.name === this.name)
  let comboconstituents = ''
  let allcomboconstituents = ''
  for (let index in combomemberships) {
    let test = store.getters[
      'moduleBonusLocations/BonusLocationsCombosConstituentsGetter'
    ].filter(
      (bonuslocation) =>
        bonuslocation.comboname === combomemberships[index].comboname
    )
    comboconstituents = ''
    for (let index2 in test) {
      comboconstituents = `${comboconstituents},${test[index2].name}`
    }
    comboconstituents = `${
      combomemberships[index].comboname
    }: ${comboconstituents.substring(1, comboconstituents.length)}`
    allcomboconstituents = `${allcomboconstituents} <br /> ${comboconstituents}`
  }
  allcomboconstituents = allcomboconstituents.substr(7)
  allcomboconstituents = `This bonus is part of one or more combos.<br />${allcomboconstituents}`
  return allcomboconstituents
}
/**
 *
 * @returns {Object} {valid: Boolean, message: String, type: success|warning|error}
 */
RouteStopsPrototype.prototype.validateTimerestrictions = function () {
  if (!store.state.moduleUser.UserProfile.activerallyid) return true
  let activerally = store.getters['moduleRallies/RalliesGetRallyByIdGetter'](
    store.state.moduleUser.UserProfile.activerallyid
  )
  let bonuslocation = store.getters[
    'moduleBonusLocations/BonusLocationsByNameGetter'
  ](this.name)

  // No timerestrictions for an allday bonus
  if (bonuslocation.category === 'A')
    return { valid: true, messsage: '', type: 'success' }

  // Daylight only bonus: make sure the arrivatl time is between sunrise and sunset
  if (bonuslocation.category === 'D') {
    let arrivalTime = DateTime.fromISO(this.map.arrivaltime, {
      setZone: true
    })
    let sunriseTime = DateTime.fromISO(this.map.arrivaltime, {
      setZone: true
    }).set({
      hour: DateTime.fromISO(bonuslocation.sunrise).hour,
      minute: DateTime.fromISO(bonuslocation.sunrise).minute
    })
    let sunsetTime = DateTime.fromISO(this.map.arrivaltime, {
      setZone: true
    }).set({
      hour: DateTime.fromISO(bonuslocation.sunset).hour,
      minute: DateTime.fromISO(bonuslocation.sunset).minute
    })
    return {
      valid: arrivalTime >= sunriseTime && arrivalTime <= sunsetTime,
      message: '',
      type:
        (arrivalTime >= sunriseTime && arrivalTime <= sunsetTime) === true
          ? 'success'
          : 'error'
    }
  }

  // Timerestricted bonus: do the math
  if (bonuslocation.category === 'T') {
    let parsedrestrictions = parseTimeRestrictions(this)
    let result = evaluateTimeRestrictions(
      DateTime.fromISO(this.map.arrivaltime, { setZone: true }),
      parsedrestrictions
    )
    return {
      valid: result,
      message: '',
      type: result === true ? 'success' : 'error'
    }
  }

  // Start or end checkpoint: calculate if the finish time is before, during or after the penalty window. If before: all good, if during: calculate the penalty points, if after: DNF.
  if (bonuslocation.category === 'S') {
    if (bonuslocation.name === 'START')
      return { valid: true, message: '', type: 'success' }

    // calculate the various datetimes for comparison
    let penaltyStart = DateTime.fromISO(activerally.end.datetime, {
      setZone: true
    })
    let penaltyEnd = DateTime.fromISO(penaltyStart)
      .setZone(activerally.end.timezoneid)
      .plus({
        minutes: Number(activerally.penaltywindow)
      })
    let finishTime = DateTime.fromISO(this.map.arrivaltime).setZone(
      activerally.end.timezoneid
    )
    let deltaPenaltyStart = null
    let deltaPenaltyEnd = null

    // arrival time is before penalty window opens: all good
    if (finishTime <= penaltyStart) {
      deltaPenaltyStart = penaltyStart.diff(finishTime, ['hours', 'minutes'])
      deltaPenaltyEnd = penaltyEnd.diff(finishTime, ['hours', 'minutes'])
      return {
        valid: true,
        message: `OK! This route leaves you with ${deltaPenaltyStart.hours} hours and ${deltaPenaltyStart.minutes} minutes slacktime before the start of the penalty window.<br /> This route has a total of ${deltaPenaltyEnd.hours} hours and ${deltaPenaltyEnd.minutes} minutes until check point close.`,
        type: 'success'
      }
    }
    // arrival time is before checkpoint close but within the penalty window: calculate the penalty points
    if (finishTime <= penaltyEnd) {
      deltaPenaltyStart = finishTime.diff(penaltyStart, ['hours', 'minutes'])
      let penaltypoints =
        Number(activerally.penaltypoints) *
        (Number(deltaPenaltyStart.hours) * 60 +
          Number(deltaPenaltyStart.minutes))
      return {
        valid: true,
        message: `DNF risk! This route puts you in the penalty windows by ${deltaPenaltyStart.hours} hours and ${deltaPenaltyStart.minutes} minutes and will cost you ${penaltypoints} penalty points`,
        type: 'warning'
      }
    }
    // arrival time is after checkpoint close: DNF
    deltaPenaltyEnd = finishTime.diff(penaltyEnd, ['hours', 'minutes'])
    return {
      valid: false,
      message: `DNF! This route does not work, you will be ${deltaPenaltyEnd.hours} hours and ${deltaPenaltyEnd.minutes} minutes late for the close of the check point`,
      type: 'error'
    }
  }
}
